import React, { Component } from 'react';
import axios from 'axios';

//material ui
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

//Context API
import { FirebaseContext } from '../components/Firebase';

//images
const logo = 'https://firebasestorage.googleapis.com/v0/b/voncerts-df00d.appspot.com/o/logo%2Fvoncerts-14.png?alt=media';

const openCheckout = (data, 
                        ctxt,
                        concert, 
                        history, 
                        idToken) => {
    let options = {
        "key": process.env.REACT_APP_RAZORPAY_KEY, 
        "amount": data.tPrice * 100,
        "currency": data.tCurr,
        "name": "Voncerts",
        "description": concert.cTitle,
        "image": {logo},
        "order_id": data.order_id,
        "handler": function (response){
            // console.log("response = " + response);
            // console.log("razorpay payment id = " + response.razorpay_payment_id);
            // console.log("razorpay order id = " +  response.razorpay_order_id);
            // console.log("razorpay signature = " + response.razorpay_signature);
            const userData = {
                tId: data.tId,
                oDate: concert.oDate,
                payGate: 'razorpay',
                payId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
                validity: data.validity
            }
            const eventParams = {
                artistHandle: concert.artistHandle,
                cTitle: concert.cTitle,
                cCurr: data.tCurr,
                tPrice: data.tPrice
            }
            if (typeof response.error === 'undefined') {
                //verify signature and update payment details
                axios.post('/playlist/pay', userData, {
                    headers: {'Authorization': `Bearer ${idToken}`}
                })
                .then( res => {
                    //record analytics event
                    ctxt.eventAddToPlaylist(eventParams);
                    //redirect to play concert
                    history.replace('/playlist');
                })  
                .catch( error => {
                    alert ("payment update error. order Id = " + response.razorpay_order_id);
                    console.error("payment update error. order Id = " + response.razorpay_order_id);
                    history.replace(`/`);
                });            
            } else {
                alert("razorpay  error : " + response.error.reason);
                console.error("razorpay error : " + response.error);
                history.replace(`/`);
            }
        },
        "prefill": {
            "name": ctxt.user.displayName,
            "email": ctxt.user.email,
            "contact": ctxt.user.phoneNumber
        },
        "notes": {
            "Concert_Title": concert.cTitle
        },
        "theme": {
            "color": "#F37254"
        },
        "modal": {
            "ondismiss": function(){
                if (concert.source === 'event') {
                    history.replace(`/events/${concert.eventName}`);
                } else {
                    history.replace(`/artists/${concert.artistHandle}`);
                }
            }
        } 
    };
    var rzp = new window.Razorpay(options);
    rzp.open();
}

class addToPlaylist extends Component {
    componentDidMount(){
        //load razorpay checkout js
        const razorPayScript = document.createElement("script");
        razorPayScript.src =  "https://checkout.razorpay.com/v1/checkout.js";
        razorPayScript.async = true;
        document.body.appendChild(razorPayScript);

        //fetch idToken
        let idToken = "";
        if (this.context.user) {
            this.context.doGetIdToken()
            .then((token) => {
                idToken = token;
                this.context.remoteConfig.fetchAndActivate()
                    .then(() => {
                        if (this.props.location.state.concert.source === "preview" &&
                            this.props.location.state.concert.priceIndex === null) {
                            // console.log('addGuest');
                            const userData = {
                                cId: this.props.location.state.concert.cId,
                                couponCode: this.props.location.state.concert.couponCode,
                                country_code: this.context.remoteConfig.getString('country'),
                            }
                            // console.log(userData);
                            //add to playlist
                            return axios.post('/playlist/addGuest', userData, {
                                headers: {'Authorization': `Bearer ${idToken}`}
                            });
    
                        } else {
                            // console.log('add');
                            const userData = {
                                country_code: this.context.remoteConfig.getString('country'),
                                cId: this.props.location.state.concert.cId,
                                priceIndex: this.props.location.state.concert.priceIndex,
                                pTimingsIndex: this.props.location.state.concert.pTimingsIndex,
                                priceOpen: this.props.location.state.concert.priceOpen
                            }
                            // console.log(userData);
                            //add to playlist
                            return axios.post('/playlist/add1', userData, {
                                headers: {'Authorization': `Bearer ${idToken}`}
                            });    
                        }
                    })
                    .then(res => {
                        switch(res.status){
                            //201 = added to playlist
                            //209 = already in playlist
                            //202 = redirect to payment
                            case 201:
                            case 209:
                                this.props.history.replace('/playlist');
                                break;
                            case 202:
                                if (res.data.payGate === "razorpay"){
                                    openCheckout(res.data, 
                                        this.context,
                                        this.props.location.state.concert,
                                        this.props.history,
                                        idToken);    
                                } else if (res.data.payGate === "paypal"){
                                    //paypal checkout for international payments
                                    const product = {
                                        cTitle: this.props.location.state.concert.cTitle,
                                        cStatus: this.props.location.state.concert.cStatus,
                                        cImg: this.props.location.state.concert.cImg,
                                        liveImg: this.props.location.state.concert.liveImg,
                                        oDate: this.props.location.state.concert.oDate,
                                        tCurr: res.data.tCurr,
                                        tPrice: res.data.tPrice,
                                        tId: res.data.tId,
                                        order_id: res.data.order_id,
                                        validity: res.data.validity,
                                        source: this.props.location.state.concert.source,
                                        eventName: this.props.location.state.concert.eventName,
                                        artistHandle: this.props.location.state.concert.artistHandle,
                                        idToken: idToken
                                    }
                                    this.props.history.replace({
                                        pathname: '/paypalButton',
                                        state: {product}
                                    });
                                } else {
                                    console.error('Unknown error');
                                    alert("Unknown Error. Please try again ");
                                    if (this.props.location.state.concert.source === 'event') {
                                        this.props.history.replace(`/events/${this.props.location.state.concert.eventName}`);
                                    } else {
                                        this.props.history.replace(`/artists/${this.props.location.state.concert.artistHandle}`);
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                        // console.log(res);
                    })
                    .catch(err => {
                        console.error('Unexpected error : ' + err);
                        alert("Unexpected Error. Please try again ");
                        if (this.props.location.state.concert.source === 'event') {
                            this.props.history.replace(`/events/${this.props.location.state.concert.eventName}`);
                        } else {
                            this.props.history.replace(`/artists/${this.props.location.state.concert.artistHandle}`);
                        }
                    });
            })
            .catch(err => {
                alert("Session expired. Please login : " + err);
                this.props.history.push('/login');
            });
        } else {
            alert("Session expired. Please login");
            this.props.history.push('/login');
        }
    }

    render() {
        const { concert } = this.props.location.state;
        const { cId, oDate, artistHandle, cTitle, cImg, 
                liveImg, source, couponCode, priceIndex } = concert;
        const { classes } = this.props;

        return (
                <Grid container 
                    // className={ classes.gridContainer } 
                    spacing={2} 
                    justify='flex-start'
                    alignItems='center' >
                <Grid item xs={12} align="center"> 
                    <CircularProgress variant="indeterminate" 
                        color="secondary" size={80} />
                </Grid>
            </Grid>
        );            
    }
}
addToPlaylist.contextType = FirebaseContext;

export default addToPlaylist
