import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { FixedSizeList } from "react-window";

//Context API
import { FirebaseContext } from "../components/Firebase";

//function component
import ListItemFunction from "./ListItemFunction";

const styles = {
  base: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  inline: {
    display: "inline",
  },
  list: {
    // flex: "1 1 auto",
    height: "100%",
  },
};

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listItems: [],
      listItemCount: 0,
    };
  }

  componentDidMount() {
    //set the query listener
    let query = this.context.getMessage(this.props.cId);
    this.unsubscribe = query.onSnapshot((snapshot) => {
      let items = [];
      snapshot.forEach((doc) => {
        let item = {
          displayName: doc.data().displayName,
          photoUrl: doc.data().photoUrl,
          message: doc.data().message,
          cTmstmp: doc.data().cTmstmp,
        };
        items.push(item);
      });

      this.setState({
        listItems: [...items],
        listItemCount: items.length,
      });
    });
  }

  componentWillUnmount() {
    //remove listener for new messages
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { classes, cId } = this.props;
    let Row, itemSize, itemCount;

    if (this.state.listItems.length === 0) {
      itemSize = 500;
      itemCount = 1;
      Row = ({ index, style }) => (
        <ListItemFunction
          aSrc=""
          dName="Start Chatting"
          msg=""
          cTmstmp=""
          style={style}
          index={index}
        />
      );
    } else {
      itemSize = 170;
      itemCount = this.state.listItems.length;
      Row = ({ index, style }) => (
        <ListItemFunction
          aSrc={this.state.listItems[index].photoUrl}
          dName={this.state.listItems[index].displayName}
          msg={this.state.listItems[index].message}
          cTmstmp={this.state.listItems[index].cTmstmp}
          style={style}
          key={index}
        />
      );
    }

    return (
      <FixedSizeList
        height={550}
        width="100%"
        style={{ backgroundColor: "white" }}
        itemSize={itemSize}
        itemCount={itemCount}
      >
        {Row}
      </FixedSizeList>
    );
  }
}
MessageList.contextType = FirebaseContext;

export default withStyles(styles)(MessageList);
