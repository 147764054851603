import React, { Component } from 'react';
import axios from 'axios';

//Material ui
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

//Context API
import { FirebaseContext } from '../components/Firebase';

//Components
import VideoPlayer from '../components/VideoPlayer';
import Comments from '../components/Comments';
import Interaction from '../components/Interaction';

const styles = {
    sectionContainer: {
        direction: "row",
        alignItems: "flex-start",
        justify: "center"
    },
    pagePaper: {
        background: "#f5f5f5",
        // width: "100vw"
        // align: "center",
        // display: "block",
        // paragraph: true
    },
    pageCard: {
        backgroundColor: "f5f5f5"
    }
}

class playConcert extends Component {
    state = {
        playVideo: -1,
        cUrl: "",
        liveUrl: "",
        cTitle: "",
        cStatus: "",
        cComments: "",
        cFBAppId: "",
        cSongs: "",
        viewsCount: 0
    }

    componentDidMount(){
        window.scrollTo(0,0);
        //fetch idToken
        let idToken = "";
        if (typeof this.context.user === "object" && 
                    this.context.user !== null) {
            this.context.doGetIdToken()
                .then((token) => {
                    idToken = token;
                    return this.context.remoteConfig.fetchAndActivate();
                })
                .then(() => {
                    const userData = {
                        country_code: this.context.remoteConfig.getString('country'),
                        tId: this.props.location.state.playlistItem.tId
                    }
                    return axios.post('/playlist/play', userData, {
                        headers: {'Authorization': `Bearer ${idToken}`}
                    });
                })
                .then(res => {
                    if (res.data.valid === 0){
                        alert(res.data.reason);
                        let artistHandle = this.props.location.state.playlistItem.artistHandle;
                        this.props.history.replace(`/artists/${artistHandle}`);
                    } else {
                        //prepare markup for valid video
                        this.setState({
                            playVideo: res.data.valid,
                            cUrl: res.data.cUrl,
                            liveUrl: res.data.liveUrl,
                            cTitle: res.data.cTitle,
                            cStatus: res.data.cStatus,
                            cComments: res.data.cComments,
                            cFBAppId: res.data.cFBAppId,
                            cSongs: JSON.parse(JSON.stringify(res.data.cSongs)),
                            viewsCount: res.data.cViews
                        });
                        //record analytics event
                        const eventParams = {
                            artistHandle: this.props.location.state.playlistItem.artistHandle,
                            cTitle: res.data.cTitle
                        }
                        this.context.eventViewConcert(eventParams);
                    }
                })
                .catch(err => {
                    //show error and redirect to home
                    console.error(err)
                    alert("Error : " + err);
                    this.props.history.replace('/');
                });
        } else {
            alert("Auth missing. Redirecting to artist page");
            let artistHandle = this.props.location.state.playlistItem.artistHandle;
            this.props.history.replace(`/artists/${artistHandle}`);
        }
    }

    render(){
        const {classes} = this.props;
        const { tId, cId, eventName, artistHandle, cMedia, 
            cImg, cOGDesc, tVDate } = this.props.location.state.playlistItem;

        let premiere = false;
        let videoMarkup;
        let commentsMarkup;
        if (this.state.playVideo === 1){
            //Pass appropriate video url to video player
            let videoUrl;
            if (this.state.liveUrl !== "") {
                premiere = true;
                videoUrl = this.state.liveUrl;
                commentsMarkup = <Interaction cId={cId} />
            } else {
                videoUrl = this.state.cUrl;
                commentsMarkup = <Comments cFBAppId={this.state.cFBAppId} 
                                            cTitle={this.state.cTitle} 
                                            cOGDesc={cOGDesc} 
                                            cImg={cImg}
                                            cId={cId}
                                />
            }
            videoMarkup = <VideoPlayer cUrl={videoUrl}
                            artistHandle={artistHandle}
                            cComments={this.state.cComments} 
                            cStatus={this.state.cStatus}
                            cPremiere={premiere}
                            cTitle={this.state.cTitle} 
                            cSongs={this.state.cSongs}
                            cViews={this.state.viewsCount} />
        } else {
            videoMarkup = <Grid item xs={12} align="center"> 
                                <CircularProgress variant="indeterminate" 
                                    color="secondary" size={80} />
                            </Grid>
        }

        return(
            <Grid container className={ classes.sectionContainer } 
                        spacing={2}>
                <Grid item xs={12} sm={8}>
                    {videoMarkup}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {commentsMarkup}
                </Grid>
            </Grid>
        )
    }
}
playConcert.contextType = FirebaseContext;

export default withStyles(styles)(playConcert) ;