import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

//Mui imports
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  gridPosterRow: {
    direction: "row",
    justify: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },

  postercard: {
    position: "relative",
  },
  posterMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
};

class Event extends Component {
  render() {
    const { classes, event } = this.props;
    const { eventName, eDisplayName, eImg } = event;

    // var toLink = `#`;
    var toLink = `/events/${eventName}`;

    return (
      <Grid container className={classes.gridPosterRow} spacing={1}>
        <Grid item xs={12} sm={9}>
          <Card className={classes.postercard}>
            <CardActionArea>
              <Link
                to={{
                  pathname: `/events/${eventName}`,
                  state: { eImg },
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <CardMedia
                  className={classes.posterMedia}
                  image={eImg}
                ></CardMedia>
              </Link>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Event);
