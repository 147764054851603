import React, { Component, Fragment } from "react";

import axios from "axios";

//material ui components
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

import Alert from "@material-ui/lab/Alert";

//Context API
import { FirebaseContext } from "./Firebase";

const styles = {
  card: {
    position: "relative",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  overlay: {
    position: "absolute",
    width: "30%",
    height: "4vh",
    top: "10%",
    left: "55%",
    // color: "secondary",
    // backgroundColor: '#ffffff',
    // opacity: '0.50'
  },
};

class Announcements extends Component {
  state = {
    announcements: null,
  };

  componentDidMount() {
    const userData = {
      params: {
        target: this.props.target,
      },
    };
    //fetch announcements
    axios
      .get("/announcements/getAnnouncements", userData)
      .then((res) => {
        this.setState({
          announcements: res.data,
        });
      })
      .catch((err) => {
        alert("Unexpected Error : " + err);
        console.error(err);
      });
  }

  render() {
    const { classes } = this.props;
    let announcementMarkup = [];

    if (this.state.announcements) {
      this.state.announcements.forEach((announcement) =>
        announcementMarkup.push(
          <Alert
            severity={announcement.severity}
            color={announcement.color}
            variant="filled"
          >
            {announcement.message}
          </Alert>
        )
      );
    } else {
      announcementMarkup = (
        <CircularProgress variant="indeterminate" color="secondary" size={80} />
      );
    }

    return <Fragment>{announcementMarkup}</Fragment>;
  }
}
Announcements.contextType = FirebaseContext;

export default withStyles(styles)(Announcements);
