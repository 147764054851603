import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';

//Context api
import { FirebaseContext } from './Firebase';
import { AuthUserContext } from './Session';

export default function MenuListComposition() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
      <AuthUserContext.Consumer>
        { context => ( 
            <Fragment>
                {/* { !context.authUser &&  */}
                { (typeof context.authUser === 'object' &&
                            context.authUser === null) && 
                    <Fragment>
                      <Button variant="contained" 
                              color="secondary" 
                              component={Link}
                              to="/login">
                        Login
                      </Button>
                    </Fragment>
                }
                { context.authUser && 
                    <Fragment>
                    <IconButton
                        color='secondary'
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                      {/* <div><small>{context.authUser.displayName.slice(0,1)}</small></div> */}
                      <Avatar alt={context.authUser.email.slice(0,1)} 
                              src={context.authUser.photoURL} />
                    </IconButton>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  <div align="center"><small>Hi</small></div>
                                  <div align="center"><small>{context.authUser.displayName}</small></div>
                                  <div align="center"><small>({context.authUser.email})</small></div>
                                <hr></hr>
                                < FirebaseContext.Consumer>
                                    { fbContext => (
                                        <Fragment>
                                          {(fbContext.claims.super ||
                                            fbContext.claims.admin ||
                                            fbContext.claims.organizer) &&
                                            <MenuItem 
                                                onClick={handleClose}
                                                component={Link}
                                                to='/admin'>
                                                  Admin
                                              </MenuItem>
                                          }

                                          <MenuItem onClick={(event) => 
                                            {
                                              handleClose(event);
                                              fbContext.doSignOut();
                                            }}
                                              component={Link} to='/'
                                              >Logout</MenuItem>
                                        </Fragment>
                                      )
                                    }
                                </FirebaseContext.Consumer>
                                </MenuList>
                            </ClickAwayListener>
                            </Paper>
                        </Grow>
                        )}
                    </Popper>
                    </Fragment>
                }
            </Fragment>
       )}

   </AuthUserContext.Consumer>

  );
}
