import React, { Component, Fragment } from 'react';

//Context api
import { FirebaseContext } from "../components/Firebase";
import { AuthUserContext } from "../components/Session";

export class termsAndPrivacy extends Component {
    render(){
        return(
            <div>
<p><strong>TABLE OF CONTENTS</strong></p>
<p><strong>&nbsp;</strong></p>
<ul>
<li><a href="#tos"><strong>TERMS OF USE</strong></a></li>
<li><a href="#refund"><strong>REFUND AND CANCELLATION</strong></a></li>
<li><a href="#privacy"><strong>PRIVACY POLICY</strong></a></li>
</ul>
<p>&nbsp;</p>
<p id="tos"><strong>TERMS OF USE</strong></p>
<p>Posted as of _24_ May 2020</p>
<p>Last updated as of _24_ May 2020</p>
<p>&nbsp;</p>
<p>Welcome to</p>
<p><strong><em>&ldquo;www.voncerts.com&rdquo;</em></strong></p>
<p><strong>&nbsp;</strong></p>
<ol>
<li><strong>GENERAL</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>We, <strong>M/s. </strong><strong>VONCERTS</strong>, a proprietorship business, having its registered office at <strong><em>&ldquo;6, SAI PREMA, 68 GREENWAYS ROAD EXTENSION, RAJA ANNAMALAI PURAM, CHENNAI, TAMILNADU-600028&rdquo;</em></strong>, represented by its Proprietor, Mrs. P. R. Rajalakshmi, hereinafter referred to as the <strong>&ldquo;Business&rdquo;</strong> (where such expression shall, unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives, administrators, permitted successors and assigns).</p>
<p style={{paddingLeft: "30px"}}>The Business ensures steady commitment to Your usage of the Platform and privacy with regard to the protection of Your invaluable information. This document contains information about the Website and domains <strong><em>&ldquo;</em></strong><strong><em>www.voncerts.com&rdquo; and accompanying mobile apps </em></strong>(hereinafter referred to as the &ldquo;<strong>Platform</strong>&rdquo;).</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<p style={{paddingLeft: "30px"}}>For the purpose of these Terms of Use (&ldquo;<strong>Terms</strong>&rdquo;), wherever the context so requires,</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li style={{paddingLeft: "30px"}}><em>&ldquo;We&rdquo;</em>, <em>&ldquo;Our&rdquo;</em>, and <em>&ldquo;Us&rdquo;</em> shall mean and refer to the Domain and/or the Business, as the context so requires<strong>.</strong></li>
<li style={{paddingLeft: "30px"}}><em>&ldquo;You&rdquo;</em>, <em>&ldquo;Your&rdquo;</em>, <em>&ldquo;Yourself&rdquo;</em>,<em> &ldquo;</em><em>User&rdquo;</em> shall mean and refer to natural and legal individuals who use the Platform and who is competent to enter into binding contracts, as per Indian laws.</li>
<li style={{paddingLeft: "30px"}}><em>&ldquo;Partner&rdquo; </em>shall mean and refer to the entities that provide the information and resources that the Domain and/or Business makes available to the users.</li>
<li style={{paddingLeft: "30px"}}><em>&ldquo;</em><em>Services</em><em>&rdquo; </em>shall refer to Services provided by the Business, which shall mainly refer to providing a platform for the Users to avail services offered by partners on this Platform. The detailed explanation shall be provided in Clause 3 of these Terms of Use.</li>
<li style={{paddingLeft: "30px"}}><em>&ldquo;Third Parties&rdquo;</em> refer to any Application, company or individual apart from the User, Partner, and the creator of this Application.</li>
<li style={{paddingLeft: "30px"}}>The term <em>&ldquo;Platform&rdquo;</em> refers to the Website/Domain and the accompanying mobile apps created by the Business which provides the Client with the services of the Business thru the use of the platform.</li>
<li style={{paddingLeft: "30px"}}><strong>The headings of each section in these Terms are only for the purpose of organizing the various provisions under these Terms in an orderly manner and shall not be used by either Party to interpret the provisions contained herein in any manner. Further, it is specifically agreed to by the Parties that the headings shall have no legal or contractual value.</strong></li>
<li style={{paddingLeft: "30px"}}>The use of this Platform by the Users is solely governed by these Terms as well as the Privacy Policy and other policies as listed on the Platform, and any modifications or amendments made thereto by the Business, from time to time, at its sole discretion. If You continue to access and use this Platform, you are agreeing to comply with and be bound by the following Terms and Conditions of Use and Our Privacy Policy. The User expressly agrees and acknowledges that these Terms and Policy are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.</li>
<li style={{paddingLeft: "30px"}}>The User unequivocally agrees that these Terms and the aforementioned Policy constitute a legally binding agreement between the User and the Business, and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the Platform, and that the same shall be deemed to be incorporated into these Terms, and shall be treated as part and parcel of the same. The User acknowledges and agrees that no signature or express act is required to make these Terms and the Policy binding on the User and that the User&rsquo;s act of visiting any part of the Platform constitutes the User&rsquo;s full and final acceptance of these Terms and the aforementioned Policy.</li>
<li style={{paddingLeft: "30px"}}>The Business reserves the sole and exclusive right to amend or modify these Terms without any prior permission or intimation to the User, and the User expressly agrees that any such amendments or modifications shall come into effect immediately. The User has a duty to periodically check the terms and stay updated on its requirements. If the User continues to use the Platform following such a change, the User will be deemed to have consented to any and all amendments/modifications made to the Terms. In so far as the User complies with these Terms, it is granted a personal, non-exclusive, non-transferable, revocable, limited privilege to access and use the Platform and the Services. If the User does not adhere to the changes, You must stop using the Services at once. Your continued use of the Services will signify your acceptance of the changed terms.</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong>REGISTRATION</strong></li>
</ol>
<ol style={{listStyleType: "lower-alpha"}}>
<li style={{paddingLeft: "30px"}}>In order to use the platform, Registration is mandatory for all users of the Platform. Public users can register using their Google sign-in</li>
<li style={{paddingLeft: "30px"}}>The registration of partners is mandatory for any partner looking to work with VONCERTS. The registration of partners will be managed mutually by VONCERTS and the partner.</li>
</ol>
<p style={{paddingLeft: "30px"}}><strong>&nbsp;</strong></p>
<p style={{paddingLeft: "30px"}}><strong>General Terms</strong></p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>Registration for this Platform is available only to those above the age of Eighteen (18) years, barring those <strong><em>&ldquo;Incompetent to Contract&rdquo;</em></strong> which <em>inter alia </em>include insolvents. If you are a minor and wish to use the Platform as a User, You may do so through your legal guardian and the Business reserves the right to terminate your account on knowledge of you being a minor and having registered on the Platform or availing any of its Services.</li>
<li>Further, at any time during Your use of this Platform, including but not limited to the time of registration, You are solely responsible for protecting the confidentiality of Your Username and password, and any activity under the account shall be deemed to have been done by You. In the case that you provide us with false and/or inaccurate details or we have reason to believe you have done so, we hold the right to permanently suspend your account. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your account, whether or not you have authorized such activities or actions. You will immediately notify us of any herein below the use of your account.</li>
</ol>
<p>&nbsp;&nbsp;</p>
<ol start="3">
<li><strong>PLATFORM OVERVIEW</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>The Platform/domain is owned, created and operated by <strong>M/s VONCERTS, </strong>a proprietorship Business. COVID19 pandemic has forced all live entertainment platforms to stop functioning, at-least temporarily impacting several performing artists. VONCERTS aims to provide a virtual platform for performing artists to publish their performances and get monetary remuneration.</p>
<p style={{paddingLeft: "30px"}}>Performing artists can record their performances and post it on the VONCERTS platform. Users and fans can sign-in into the VONCERTS platform, browse the available performances of different artists, purchase tickets to their preferred concerts, add them to their playlist and watch.</p>
<p style={{paddingLeft: "30px"}}>The platform, is merely a facilitator, designed to bring artist performances on a virtual platform for end users and fans.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="4">
<li><strong>ELIGIBILITY</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>The Users jointly represents and warrants that they are competent and eligible to enter into legally binding agreements and of 18 years of age and that they have the requisite authority to bind themselves to these Terms in accordance with the Law.</p>
<p style={{paddingLeft: "30px"}}>The Users further represents that they will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations.</p>
<p style={{paddingLeft: "30px"}}>The Users may not use the Platform if they are not competent to contract or are disqualified from doing so by any other applicable law, rule or regulation currently in force.</p>
<p style={{paddingLeft: "30px"}}><strong>&nbsp;</strong></p>
<ol start="5">
<li><strong>CONTENT</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>All text, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, <strong>&lsquo;Content&rsquo;</strong>),&nbsp; generated/provided or based on information provided by Partners or third parties the Platform has no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such offers, agreements, statements or such other information provided by the Partners or third parties on the Platform.</p>
<p style={{paddingLeft: "30px"}}>All the Content displayed on the Platform is subject to copyright and shall not be reused by any party (or a third party) without the prior written consent of the Business and the copyright owner.</p>
<p style={{paddingLeft: "30px"}}>The Partners are solely responsible for the integrity, authenticity, quality and genuineness of the content provided by them on the Platforms and whilst feedback and comments by Users can be made via the Platform, the Platform bears no liability whatsoever for any feedback or comments made by the Users or made in respect of any of the content on the Platform. Further, the Platform reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Platform or to terminate the account of any User who is found to have created or shared or submitted any Content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. The Partner shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.</p>
<p style={{paddingLeft: "30px"}}>The Users have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the Content on the Platform. Users shall not copy, adapt, and modify any content without written permission of the Business.</p>
<p>&nbsp;</p>
<ol start="6">
<li><strong>TERM</strong>&nbsp;</li>
</ol>
<ol style={{listStyleType: "lower-alpha"}}>
<li>These Terms shall continue to form a valid and binding contract between the Parties and shall continue to be in full force and effect until the Users and Partners continue to access and use the Platform</li>
<li>The Users may terminate their use of the Platform at any time.</li>
<li>The Partners may terminate their use of the Platform at any time.</li>
<li>The Business may terminate these Terms and close a User&rsquo;s account at any time without notice and/or suspend or terminate a User&rsquo;s and/or Partner&rsquo;s access to the Platform at any time and for any reason, if any discrepancy or legal issue arises.</li>
<li>Such suspension or termination shall not limit our right to take any other action against you that the Business considers appropriate.</li>
<li>It is also hereby declared that the Business may discontinue the Services and Platforms without any prior notice.</li>
</ol>
<p>&nbsp;</p>
<ol start="7">
<li><strong>TERMINATION</strong></li>
</ol>
<ol style={{listStyleType: "lower-alpha"}}>
<li>The Business reserves the right, in its sole discretion, to unilaterally terminate the User&rsquo;s access to the Platform, or any portion thereof, at any time, without notice or cause.</li>
<li>The Platform also reserves the universal right to deny access to particular Users, to any/all of are on its Platform without any prior notice/explanation in order to protect the interests of the Platform and/or other visitors to the Platform.</li>
<li>The Platform reserves the right to limit, deny or create different access to the Platform and its features with respect to different Users, or to change any of the features or introduce new features without prior notice.</li>
<li>The User shall continue to be bound by these Terms, and it is expressly agreed to by the Parties that the User shall not have the right to terminate these Terms till the expiry of the same.&nbsp;</li>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li><strong>COMMUNICATION</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>By using this Platform and providing his/her identity and contact information to the Business through the Platform, the Users hereby agree and consent to receive calls, e-mails, Whatsapp messages or SMS from the Business and/or any of its representatives at any time.</p>
<h3 style={{paddingLeft: "30px"}}>Users can report to <strong><em>"voncerts@gmail.com&rdquo;</em></strong> if they find any discrepancy with regard to Platform or content-related information and the Business will take necessary action after an investigation.&nbsp; The response with resolution (if any issues found) shall be dependent on the time taken for investigation.</h3>
<p style={{paddingLeft: "30px"}}>The User expressly agrees that notwithstanding anything contained hereinabove, it may be contacted by the Business or any representatives relating to any Product purchased by the User on the Platform or anything pursuant thereto and the Users agrees to indemnify the Business from any and all harassment claims. It is expressly agreed to by the Parties that any information shared by the User with the Business shall be governed by the Privacy Policy.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="9">
<li><strong>PAYMENT</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}><em><u>Charges and Terms </u></em></p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>The pricing for each content published in this platform is decided by the producer of the content and it may vary over time</li>
<li>The users will have to enable location access in their devices to have the ability to view content pricing and purchase them</li>
<li>The Users can avail the content available on the platform by making an online payment for the selected content</li>
<li>On purchase of a content, the content remains accessible to the user on the platform for a fixed duration as determined by the platform</li>
<li>The user is allowed to watch their purchased content any number of times on the platform during the content access validity period</li>
</ol>
<p>&nbsp;</p>
<p style={{paddingLeft: "30px"}}>The Business will not be liable for any credit or debit card fraud. The liability to use a card fraudulently will be on the user and the onus to 'prove otherwise' shall be exclusively on the user. To provide a safe and secure purchase experience, the Business regularly monitors transactions for fraudulent activity. In the event of detecting any suspicious activity, the Business reserves the right to cancel all past, pending and future purchases without any liability.</p>
<p style={{paddingLeft: "30px"}}>The Business shall disclaim all responsibility and owns no liability to Users for any outcome (incidental, direct, indirect or otherwise) from the use of the Services.&nbsp; The Business, as a merchant, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="10">
<li><strong>USER</strong><strong> OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>The User agrees and acknowledges that they are a restricted user of this Platform and that they:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>Agree to provide genuine credentials during the process of registration on the Platform. You shall not use a fictitious identity to register. Business is not liable if the User has provided incorrect information.</li>
<li>Agree to ensure the Name, Email address, Mobile number and any such other information provided during account registration is valid at all times and shall keep your information accurate and up-to-date.</li>
<li>Users agree that they are solely responsible for maintaining the confidentiality of your account password. You agree to notify us immediately of any unauthorized use of your account. Business reserves the right to close your account at any time for any or no reason.</li>
<li>Understand and acknowledge that the data submitted is entered into the database of the Platform. The User also acknowledges the fact that data so entered into the database is for the purpose of easy and ready reference for the User, and to streamline the Services through the Platform.</li>
<li>Authorize the Platform to use, store or otherwise process certain personal information and all published Content, User responses, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimisation of User-related options and Services.</li>
<li>Understand and agree that, to the fullest extent permissible by law, the Platform/Business and their successors and assigns, or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from use of the Platform or from this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</li>
<li>Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the Platform. Any such use/limited use of the Platform will only be allowed with the prior express written permission of the Business.</li>
<li>Agree not to access (or attempt to access) the Platform and/or the materials or Services by any means other than through the interface provided by the Platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or its content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of the User&rsquo;s access to the Platform. The User acknowledges and agrees that by accessing or using the Platform or any of the Services provided therein, it may be exposed to content that it may consider offensive, indecent or otherwise objectionable. The Business disclaims any and all liabilities arising in relation to such offensive content on the Platform.</li>
<li>Expressly agree and acknowledge that the Content generated by the Users and displayed on the Platform is not owned by the Business and that the Business is in no way responsible for the content of the same. The User may, however, report any offensive or objectionable content, which the Business may then remove from the Platform, at its sole discretion.</li>
<li>Expressly consents to follow the terms and conditions, and policies of the Partner affiliated with the Business from whom the Users are utilizing services.</li>
</ol>
<p>&nbsp;</p>
<p style={{paddingLeft: "30px"}}>The User and Partner further undertake not to:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>Engage in any activity that interferes with or disrupts access to the Platform or the Services provided therein (or the servers and networks which are connected to the Platform);</li>
<li>Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;</li>
<li>Probe, scan or test the vulnerability of the Platform or any network connected to the Platform, nor breach the security or authentication measures on the Platform or any network connected to the Platform. The User may not reverse look-up, trace or seek to trace any information relating to any other User of, or visitor to, the Platform, or any other viewer of the Platform, including any User account maintained on the Platform not operated/managed by the User, or exploit the Platform or information made available or offered by or through the Platform, in any manner;</li>
<li>Disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources, accounts, passwords, servers or networks connected to or accessible through the Platform or any affiliated or linked Platforms;</li>
<li>Use the Platform or any material or content therein for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Platform or any other third party (s);</li>
<li>Violate any code of conduct or guideline which may be applicable for or to any particular service offered on the Platform;</li>
<li>Violate any applicable laws, rules or regulations currently in force within or outside India;</li>
<li>Violate any portion of these Terms or the Privacy Policy, including but not limited to any applicable additional terms of the Platform contained herein or elsewhere, whether made by amendment, modification, or otherwise;</li>
<li>Commit any act that causes the Business to lose (in whole or in part) the Services of its Internet Establishment ("<strong>ISP</strong>") or in any manner disrupts the Services of any other supplier/service provider of the Business/Platform;</li>
</ol>
<p style={{paddingLeft: "30px"}}>Further:</p>
<p style={{paddingLeft: "30px"}}>j. The User hereby expressly authorizes the Business/Platform to disclose any and all information relating to the User in the possession of the Business/Platform to law enforcement or other government officials, as the Business may in its sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. The User further understands that the Business/Platform might be directed to disclose any information (including the identity of persons providing information or materials on the Platform) as necessary to satisfy any judicial Order, law, regulation or valid governmental request.</p>
<p style={{paddingLeft: "30px"}}>k. By indicating User's acceptance to purchase any product or service offered on the site, the user is obligated to complete such transactions after making payment. Users shall prohibit from indicating their acceptance to avail services where the transactions have remained incomplete.</p>
<p style={{paddingLeft: "30px"}}>l. The User agrees to use the services provided by the Business, its affiliates, consultants and contracted companies, for lawful purposes only.</p>
<p style={{paddingLeft: "30px"}}>m. The User agrees not to make any bulk purchase to indulge in any resale activities. In case of any such instances, the Business reserves all rights to cancel the current and future orders and block the concerned User account.</p>
<p style={{paddingLeft: "30px"}}>n. The User agrees to provide authentic and true information. The Business reserves the right to confirm and validate the information and other details provided by the User at any point of time. If upon confirmation such User details are found to be false, not to be true (wholly or partly), the Business shall in its sole discretion reject the registration and debar the User from using the Services available on its Website, and/or other affiliated websites without prior intimation whatsoever.</p>
<p style={{paddingLeft: "30px"}}>o. The User agrees not to post any material on the website that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful, or advertising any goods or services. More specifically, the User agrees not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:</p>
<p style={{paddingLeft: "60px"}}>i. belongs to another person and to which the User has no right to;</p>
<p style={{paddingLeft: "60px"}}>ii. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</p>
<p style={{paddingLeft: "60px"}}>iii. is in any way harmful to minors;</p>
<p style={{paddingLeft: "60px"}}>iv. infringes any patent, trademark, copyright or other proprietary rights;</p>
<p style={{paddingLeft: "60px"}}>v. violates any law for the time being in force;</p>
<p style={{paddingLeft: "60px"}}>vi. deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
<p style={{paddingLeft: "60px"}}>vii. Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</p>
<p style={{paddingLeft: "60px"}}>viii. Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</p>
<p style={{paddingLeft: "60px"}}>ix. Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
<p style={{paddingLeft: "60px"}}>x. Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</p>
<p>&nbsp;</p>
<ol start="11">
<li><strong>SUSPENSION OF </strong><strong>USER</strong><strong> ACCESS AND ACTIVITY </strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>Notwithstanding other legal remedies that may be available, the Business may in its sole discretion, limit the User&rsquo;s access and/or activity by immediately removing the User&rsquo;s access credentials either temporarily or indefinitely, or suspend/terminate the User&rsquo;s association with the Platform, and/or refuse to usage of the Platform to the User, without being required to provide the User with notice or cause:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>If the User is in breach any of these Terms or the Policy;</li>
<li>If the User has provided wrong, inaccurate, incomplete or incorrect information;</li>
<li>If the User&rsquo;s actions may cause any harm, damage or loss to the other Users or to the Business, at the sole discretion of the Business.</li>
</ol>
<p>&nbsp;</p>
<ol start="12">
<li><strong>INDEMNITY</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>The Users of this Platform agree to indemnify, defend and hold harmless the Business/Platform, and their respective directors, officers, employees and agents (collectively, <strong>"Parties"</strong>), from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed pursuant to these terms of use. Further, the User agrees to hold the Business/Platform harmless against any claims made by any third party due to, or arising out of, or in connection with:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>User&rsquo;s use of the Platform,</li>
<li>User&rsquo;s violation of these Terms and Conditions;</li>
<li>User&rsquo;s violation of any rights of another;</li>
<li>User&rsquo;s alleged improper conduct pursuant to these Services;</li>
<li>User&rsquo;s conduct in connection with the Platform;</li>
</ol>
<p>&nbsp;</p>
<p style={{paddingLeft: "30px"}}>User agrees to fully cooperate in indemnifying the Business and the Platform at the user&rsquo;s expense. The user also agrees not to reach a settlement with any party without the consent of the Business.</p>
<p style={{paddingLeft: "30px"}}>In no event shall the Business/Platform be liable to compensate the User or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not the Business/ Platform had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the User&rsquo;s use of or access to the Platform and/or the Services or materials contained therein.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="13">
<li><strong>LIMITATION OF LIABILITY</strong></li>
</ol>
<ol style={{listStyleType: "lower-alpha"}}>
<li>The Founders/Promoters/Associated people of the Business/Platform are not responsible for any consequences arising out of the following events:</li>
</ol>
<p style={{paddingLeft: "60px"}}>i. If the Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</p>
<p style={{paddingLeft: "60px"}}>ii. If the Partner or User has fed incorrect information or data or for any deletion of data;</p>
<p style={{paddingLeft: "60px"}}>iii. If there is an undue delay or inability to communicate through email;</p>
<p style={{paddingLeft: "60px"}}>iv. If there is any deficiency or defect in the Services managed by Us;</p>
<p style={{paddingLeft: "60px"}}>v. If there is a failure in the functioning of any other service provided by the Platform.</p>
<p style={{paddingLeft: "30px"}}>b. The Platform accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to the Partner or User, their belongings, or to any third party, resulting from the use or misuse of the Platform or any service availed of by the User or Partner through the Platform. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. The Platform will not be liable to you for the unavailability or failure of the Platform.</p>
<p style={{paddingLeft: "30px"}}>c. Partners and Users are to comply with all laws applicable to them or to their activities, and with all Policies, which are hereby incorporated into this Agreement by reference.</p>
<p style={{paddingLeft: "30px"}}>d. The Platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Platform and which is incurred by you in connection with the Platform, including loss of profits; and any loss or damage incurred by you as a result of your breach of these terms.&nbsp;</p>
<p style={{paddingLeft: "30px"}}>e. To the fullest extent permitted by law, the Platform shall not be liable to you or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Platform.</p>
<p style={{paddingLeft: "60px"}}>&nbsp;</p>
<ol start="14">
<li><strong>INTELLECTUAL PROPERTY RIGHTS</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>Unless expressly agreed to in writing, nothing contained herein shall give the Partner or the User a right to use any of the Platform&rsquo;s trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by the Platform and other distinctive brand features of the Platform are the property of the Business or the respective copyright or trademark owner. Furthermore, with respect to the Platform created by the Business, the Business shall be the exclusive owner of all the designs, graphics and the like, related to the Platform.</p>
<p style={{paddingLeft: "30px"}}>The Partner and/or User may not use any of the intellectual property displayed on the Platform in any manner that is likely to cause confusion among existing or prospective Partners/Users of the Platform, or that in any manner disparages or discredits the Business/Platform, to be determined in the sole discretion of the Business.</p>
<p style={{paddingLeft: "30px"}}>The User is aware all intellectual property, including but not limited to copyrights, relating to said Services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators to the Business, or to any other User. The User is aware that the Business merely provides a Platform through which the Users can communicate and schedule services, and the Business/Platform does not own any of the intellectual property relating to the independent content displayed on the Platform, apart from originally created graphics and specified content.</p>
<p style={{paddingLeft: "30px"}}>The User is further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by the User will result in legal action being initiated against the User by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="15">
<li><strong>DISCLAIMER OF WARRANTIES AND LIABILITIES</strong></li>
</ol>
<ol style={{listStyleType: "lower-alpha"}}>
<li>The User agrees and undertakes that they are accessing the Platform at their sole risk and are that they are using their best and prudent judgment before availing any service listed on the Platform or accessing/using any information displayed thereon.</li>
<li>The User agrees that any kind of information, resources, activities, recommendations obtained/availed from Platform, written or oral, will not create any warranty and the Platform disclaims all liabilities resulting from these.</li>
<li>The Business/Platform does not guarantee that the Services contained in the Platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and the User hereby expressly accepts any and all associated risks involved with the User&rsquo;s use of the Platform.</li>
<li>The performance of the Platform depends upon the User&rsquo;s mobile phone GPS capability and internet availability and could vary by location and time. The Platform has no control over these variables. The Business is not liable for any issues that the User/Partner experience in the app.</li>
<li>It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</li>
</ol>
<p>&nbsp;</p>
<ol start="16">
<li><strong>FORCE MAJEURE</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>Neither the Business nor the Platform shall be liable for damages for any delay or failure to perform its obligations hereunder if such delay or failure is due to cause beyond its control or without its fault or negligence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.</p>
<p><strong>&nbsp;</strong></p>
<ol start="17">
<li><strong>DISPUTE RESOLUTION AND JURISDICTION</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>It is expressly agreed to by the Parties hereto that the formation, interpretation and performance of these Terms and any disputes arising therefrom will be resolved through a two-step Alternate Dispute Resolution (&ldquo;<strong>ADR</strong>&rdquo;) mechanism. It is further agreed to by the Parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or Policy.</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li><strong>Mediation</strong>: In case of any dispute between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of all Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to any other Party, the dispute will be resolved by arbitration, as detailed hereinbelow;</li>
<li><strong>Arbitration</strong>: In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration by a sole arbitrator to be appointed by the Business, and the award passed by such sole arbitrator will be valid and binding on all Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The arbitration shall be conducted in English, and the seat of Arbitration shall be <strong>Chennai, Tamil Nadu, India</strong><strong>.</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>The Parties expressly agree that the Terms of Use, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules and regulations of India.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="18">
<li><strong>NOTICES</strong></li>
</ol>
<p style={{paddingLeft: "30px"}}>Any and all communication relating to any dispute or grievance experienced by the User may be communicated to the Business by the User by emailing to <strong><em>"voncerts@gmail.com&rdquo;</em></strong>.</p>
<p style={{paddingLeft: "30px"}}>&nbsp;</p>
<ol start="19">
<li><strong>MISCELLANEOUS PROVISIONS</strong></li>
</ol>
<ol style={{listStyleType: "lower-alpha"}}>
<li><strong>Entire Agreement</strong>: These Terms, read with the Policy, form the complete and final contract between the User and the Business with respect to the subject matter hereof and supersedes all other communications, representations and agreements (whether oral, written or otherwise) relating thereto.</li>
<li><strong>Waiver</strong>: The failure of either Party at any time to require performance of any provision of these Terms shall in no manner affect such Party's right at a later time to enforce the same. No waiver by either party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms.</li>
<li><strong>Severability</strong>: If any provision/clause of these Terms is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality and enforceability of the remaining provisions/clauses of these Terms shall in no way be affected or impaired thereby, and each such provision/clause of these Terms shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</li>
<li><strong>Contact Us</strong>: If you have any questions about this Policy, the practices of the Platform, or your experience with the Service provided by the Platform, you can contact us at <strong><em>"voncerts@gmail.com</em></strong><strong><em>"</em></strong><strong><em>.<br /> </em></strong></li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p id="refund"><strong>REFUND AND CANCELLATION POLICY</strong></p>
<p>Posted as of _24_ May 2020</p>
<p>Last updated as of _24_ May 2020</p>
<p>&nbsp;</p>
<ul>
<li><strong>RETURN</strong></li>
</ul>
<p><strong><em>'Return'</em></strong> is defined as the action of giving back the item purchased on the VONCERTS platform by the Buyer to the Seller.</p>
<p><strong>&nbsp;</strong></p>
<ul>
<li><strong>REFUND</strong></li>
</ul>
<p><strong><em>&lsquo;Refund&rsquo;</em></strong> is defined as the action of giving the money used to purchase a certain good or service back to the party that made the purchase.</p>
<p>&nbsp;</p>
<ul>
<li><strong>REPLACEMENT</strong></li>
</ul>
<p><strong><em>'Replacement'</em></strong> is the action or process of replacing something in place of another. A Buyer can request for replacement whenever he is not happy with the item, the reason being damaged in shipping, Defective item, Item(s) missing, wrong item shipped and the like.</p>
<p>&nbsp;</p>
<p>Buyer is asked for <strong><em>"Reason for Refund/Return/Replacement"</em></strong>. An intimation shall be provided to Seller seeking either "approval" or "rejection" of the return/replacement request.</p>
<p>&nbsp;</p>
<p><strong>TO BE NOTED:</strong></p>
<p>We have made it evident in the Terms of Use, Disclaimer, Policy, and Privacy Policy that we are not liable for any issues that a User may encounter with the Platform, both in and out of the transactional capacity.</p>
<p>&nbsp;</p>
<p><strong>RETURN ACCEPTANCE CONDITIONS</strong></p>
<p>There are no conditions where a User is entitled to a return, refund, or replacement, as we are not liable for any issues that may occur when using our Platform. This is noted in the Terms of Use, Disclaimer Policy, and Privacy Policy.</p>
<p>&nbsp;</p>
<p><strong>IMPORTANT POINTS TO REMEMBER</strong></p>
<p>There are no conditions where a User is entitled to a return, refund, or replacement, as we are not liable for any issues that may occur when using our Platform. This is noted in the Terms of Use, Disclaimer Policy, and Privacy Policy.</p>
<p>&nbsp;</p>
<p><strong>RETURN/REPLACEMENT NON-ACCEPTANCE CONDITIONS</strong></p>
<p>There are no conditions where a User is entitled to a return, refund, or replacement, as we are not liable for any issues that may occur when using our Platform. This is noted in the Terms of Use, Disclaimer Policy, and Privacy Policy.</p>
<p>&nbsp;&nbsp;</p>
<p><strong>RETURN PROCESS</strong></p>
<p>There are no conditions where a User is entitled to a return, refund, or replacement, as we are not liable for any issues that may occur when using our Platform. This is noted in the Terms of Use, Disclaimer Policy, and Privacy Policy.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>CANCELLATION POLICY</strong></p>
<p>&nbsp;</p>
<p><strong>CUSTOMER CANCELLATION</strong></p>
<p>There are no conditions where a User is entitled to a cancellation, as we are not liable for any issues that may occur when using our Platform. This is noted in the Terms of Use, Disclaimer Policy, and Privacy Policy.</p>
<p>&nbsp;</p>
<p><strong>OTHER CANCELLATIONS</strong></p>
<p>There are no conditions where a User is entitled to a return, refund, or replacement, as we are not liable for any issues that may occur when using our Platform. This is noted in the Terms of Use, Disclaimer Policy, and Privacy Policy.</p>
<p><strong><br /> </strong></p>
<p><strong>&nbsp;</strong></p>
<p>&nbsp;</p>
<p id="privacy"><strong>PRIVACY POLICY</strong></p>
<p>Posted as of _24_ May 2020</p>
<p>Last updated as of _24_ May 2020</p>
<p>&nbsp;</p>
<p>Welcome to <strong><em>&ldquo;www.voncerts.com&rdquo;</em></strong>!</p>
<p>&nbsp;</p>
<p>We, <strong>M/s. </strong><strong>VONCERTS</strong>, a proprietorship business, having its registered office at <strong><em>&ldquo;6 SAI PREMA, 68 GREENWAYS ROAD EXTENSION, RAJA ANNAMALAI PURAM, CHENNAI, TAMILNADU-600028&rdquo;</em></strong>, represented by its Proprieter, Mrs. P.R.Rajalakshmi, hereinafter referred to as the <strong>&ldquo;Business&rdquo;</strong> (where such expression shall, unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives, administrators, permitted successors and assigns).</p>
<p>The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information.</p>
<p>This privacy policy contains information about the Website <strong><em>&ldquo;www.voncerts.com&rdquo; </em></strong>and the accompanying mobile apps (hereinafter referred to as the &ldquo;<strong>Platform</strong>&rdquo;).</p>
<p>In order to provide You with Our uninterrupted use of services, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.&nbsp;</p>
<p><strong>This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at voncerts@gmail.com</strong></p>
<p>ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</p>
<p>&nbsp;</p>
<p><strong>1. DEFINITIONS</strong></p>
<ol style={{listStyleType: "lower-alpha"}}>
<li><em>&ldquo;We&rdquo;</em>, <em>&ldquo;Our&rdquo;</em>, and <em>&ldquo;Us&rdquo;</em> shall mean and refer to the Domain and/or the Business, as the context so requires<strong>.</strong></li>
<li><em>&ldquo;You&rdquo;</em>, <em>&ldquo;Your&rdquo;</em>, <em>&ldquo;Yourself&rdquo;</em>,<em> &ldquo;</em><em>User</em><em>&rdquo;</em>, shall mean and refer to natural and legal individuals who use the Platform and who is competent to enter into binding contracts, as per Indian laws.</li>
<li><em>&ldquo;Partner&rdquo; </em>shall mean and refer to the entities that provide the information and resources that the Domain and/or Business makes available to the users.</li>
<li><em>&ldquo;</em><em>Services</em><em>&rdquo;</em> shall refer to Services provided by the Business, which shall mainly refer to providing a platform for the Users to avail services offered by partners on this Platform. The detailed explanation shall be provided in Clause 3 of these Terms of Use.</li>
<li><em>&ldquo;Third Parties&rdquo;</em> refer to any Application, company or individual apart from the User, Partner and the creator of this Application.</li>
<li>The term <em>&ldquo;Platform&rdquo;</em> refers to the Website/Domain and the accompanying mobile apps created by the Business which provides the Client to avail services of the Business thru the use of the platform.</li>
<li><em>&ldquo;Personal Information&rdquo;</em> shall mean and refer to any personally identifiable information that We may collect from You such as Name, Email Id, Mobile number, Password, Photo etc. For removal of any doubts, please refer to Clause 2.</li>
</ol>
<p>&nbsp;</p>
<p><strong>2. INFORMATION WE COLLECT</strong></p>
<p>We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>Personal data such as, but not limited to, Name, Email address, Mobile number;</li>
<li>Details of Platform usage for analytics.</li>
</ol>
<p>We may also collect and store personal information provided by You from time to time on the Platform. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>To enable the provision of services opted for by you;</li>
<li>To enable the viewing of content in your interest;</li>
<li>To communicate the necessary account and service related information from time to time;</li>
<li>To allow you to receive quality customer care services and data Collection;</li>
<li>To comply with applicable laws, rules and regulations;</li>
</ol>
<p>Where any service requested by You involves a third party, such information as is reasonably necessary by the Business to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Business may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through an email to be sent <strong><em>voncerts@gmail.com</em></strong><strong><u>.</u></strong></p>
<p>To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the platform.</p>
<p>Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</p>
<p>&nbsp;</p>
<p><strong>3. OUR USE OF YOUR INFORMATION</strong></p>
<p>The information provided by you shall be used to provide and improve the service for you and all users.</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>For maintaining an internal record.</li>
<li>For enhancing the Services provided.</li>
</ol>
<p>For more details about the nature of such communications, please refer to our Terms of Service. Further, your personal data and Sensitive Personal data may be collected and stored by Us for internal record.</p>
<p>We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under your instructions or we are required to do so by law.</p>
<p>Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.</p>
<p>&nbsp;</p>
<p><strong>4. HOW INFORMATION IS COLLECTED</strong></p>
<p>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Business to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.</p>
<p>We will collect and use your personal information solely with the objective of fulfilling those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.</p>
<p>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</p>
<p>&nbsp;</p>
<p><strong>5. GOOGLE ANALYTICS</strong></p>
<p>We may use Google Analytics to help us to understand how you make use of our content and work out how we can make things better. These analytics follow your progress through us, collecting anonymous data on where you have come from, which pages you visit, and how long you spend on the site. This data is then stored by Google in order to create reports. These analytics do not store your personal data.</p>
<p>The information generated by the analytics about your use of the Platform, including your IP address, may be transmitted to and stored by Google on servers in the United States. Google may use this information for the purpose of evaluating your use of the Platform, compiling reports on Platform activity for us and providing other services relating to Platform activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. By using this Platform, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
<p>The Google website contains further information about Analytics and a copy of Google's privacy policy pages.</p>
<p>&nbsp;</p>
<p><strong>6. EXTERNAL LINKS ON THE PLATFORM</strong></p>
<p>The Platform may include hyperlinks to other websites, applications, content or resources. We have no control over any websites or resources, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/products or other materials on or available from such platform or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such websites or resources. These external websites and resource providers may have their own privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the external Website and review their privacy policy.</p>
<p>&nbsp;</p>
<p><strong>7. YOUR RIGHTS</strong></p>
<p>Unless subject to an exemption, you have the following rights with respect to your personal data:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>The right to request a copy of your personal data which we hold about you;</li>
<li>The right to request for any correction to any personal data if it is found to be inaccurate or out of date;</li>
<li>The right to withdraw Your consent to the processing at any time;</li>
<li>The right to object to the processing of personal data;</li>
<li>The right to lodge a complaint with a supervisory authority.</li>
<li>The right to obtain information as to whether personal data are transferred to a third country or to an international organization.</li>
</ol>
<p>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold in relation to you. You are also entitled to request that we restrict how we use your data in your account when you log in.</p>
<p>&nbsp;</p>
<p><strong>8. CONFIDENTIALITY</strong></p>
<p>You further acknowledge that the Platform may contain information which is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point of time.</p>
<p>&nbsp;</p>
<p><strong>9. OTHER INFORMATION COLLECTORS </strong></p>
<p>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or on other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.</p>
<p>&nbsp;</p>
<p><strong>10. OUR DISCLOSURE OF YOUR INFORMATION </strong></p>
<p>We may host surveys for survey creators for our platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</p>
<p>Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</p>
<p>Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any and all disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.</p>
<p>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:</p>
<ol style={{listStyleType: "lower-alpha"}}>
<li>External Service Providers: There may be a number of services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or grant them permission to collect information about you, then their use of your information is governed by their privacy policy.</li>
<li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.</li>
</ol>
<p>&nbsp;</p>
<p><strong>11. ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</strong></p>
<p>Following registration, you can review and change the information you submitted at the stage of registration, except the mobile number. An option for facilitating such change shall be present on the Platform and such change shall be facilitated by the User. If you change any information, we may or may not keep track of your old information. We will not retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Such prior information shall be completely removed from our databases, including stored &lsquo;back up&rsquo; systems. If you believe that any information, we are holding on you is incorrect or incomplete, or to remove your profile so that others cannot view it, the User needs to remediate, and promptly correct any such incorrect information.</p>
<p>&nbsp;</p>
<p><strong>12. CONTROL OF YOUR PASSWORD</strong></p>
<p>You are entirely responsible for maintaining the confidentiality of your password. It is important that you protect it against unauthorized access of your account and information by choosing your password carefully and keeping your password and computer secure by signing out after using our services.</p>
<p>You agree not to use the account, username, email address or password of another Member at any time or to disclose your password to any third party. You are responsible for all actions taken with your login information and password, including fees. If you lose control of your password, you may lose substantial control over your personally identifiable information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately change your password. You agree to notify us immediately if you suspect any consistent unauthorized use of your account or access to your password even after changing it.</p>
<p>&nbsp;</p>
<p><strong>13. SECURITY</strong></p>
<p>We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Business. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</p>
<p>However, as effective as encryption technology is, no security system is impenetrable. Our Business cannot guarantee the security of our database, nor can we guarantee that information you provide won&rsquo;t be intercepted while being transmitted to the Business over the Internet.</p>
<p>&nbsp;</p>
<p><strong>14. SEVERABILITY </strong></p>
<p>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall have no effect on the remaining paragraphs of this privacy policy.</p>
<p>&nbsp;</p>
<p><strong>15. AMENDMENT </strong></p>
<p>Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be at the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.</p>
<p>&nbsp;</p>
<p><strong>16. AUTOMATED DECISION MAKING </strong></p>
<p>As a responsible Business, we do not use automatic decision-making or profiling.</p>
<p>&nbsp;</p>
<p><strong>17. CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS</strong></p>
<p>To withdraw your consent, or to request the download of your data with us for any or all our products &amp; services at any time, please email to <strong><em>voncerts@gmail.com</em></strong><strong><u>.</u></strong></p>
<p>&nbsp;</p>
<p>To delete your account for any or all our products &amp; services at any time, please login first for the delete link to appear below and then click it </p>
<p>&nbsp;</p>
<AuthUserContext.Consumer>
          {(context) => (
            <Fragment>
              <FirebaseContext.Consumer>
                {(fbContext) => (
                  <Fragment>
                    {context.authUser && (
                      <a
                        onClick={() => {
                          if (
                            window.confirm(
                              "You have clicked Delete Account. \n\nOnce deleted your existing concert playlist data on voncerts platform will be lost and cannot be retrieved!! \n\nPlease click OK to proceed"
                            )
                          ) {
                            fbContext.doUserDelete();
                          }
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        <u>Delete account</u>
                      </a>
                    )}
                  </Fragment>
                )}
              </FirebaseContext.Consumer>
            </Fragment>
          )}
        </AuthUserContext.Consumer>
        <p>&nbsp;</p>
<p><strong>18. CONTACT US </strong></p>
<p>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to please email to <strong><em>voncerts@gmail.com</em></strong><strong><u>.</u></strong></p>
<p>Information provided on the website may not be 100% accurate and may be provided for promotional purposes of the business.</p>
<p>&nbsp;</p>

            </div>
        );
    }
}


export default termsAndPrivacy