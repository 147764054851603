import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid';
import { Avatar } from '@material-ui/core';

//icons
import a from '../emojis/A-1.png';
import b from '../emojis/B-1.png';
import h from '../emojis/H-1.png'
import s from '../emojis/S-1.png';
import w from '../emojis/W-1.png';

//Context API
import { FirebaseContext } from '../components/Firebase';

class Emojis extends Component {
    constructor(props){
        super(props)
        this.state = {
            emojis: [],
            enabled: 0,
        };

        this.clickEmoji = this.clickEmoji.bind(this);
    }

    componentDidMount(){
        //load available emoji images
        let docRef = this.context.getEmojis();
        this.unsubscribe = docRef.onSnapshot((snapshot) => {
            let images = [];
            snapshot.forEach((doc) => {
                let item = {
                    name: doc.data().name,
                    img: doc.data().img,
                };
                images.push(item);
            });

            this.setState({
                emojis: [...images],
            });
        });

        //setup listener to start recording emoji clicks
        let emjRef = this.context.checkChat(this.props.cId);
        this.unsubscribeEmoji = emjRef.onSnapshot((doc) => {
            if (doc.data().liveStatus === "Start") {
                this.setState({ enabled: 1 });
            } else {
                this.setState({ enabled: 0 });
            }
        });
    }

    componentWillUnmount() {
        //remove listener for new emojis
        this.unsubscribe && this.unsubscribe();

        //remove listener for emoji clicks
        this.unsubscribeEmoji && this.unsubscribeEmoji();
    }

    clickEmoji(event) {
        //Add emoji clicks if enabled
        if (this.state.enabled === 1) {
            let emojiClick = {
                cId: this.props.cId,
                name: event.target.id,
                cTmstmp: this.context.fieldvalue.serverTimestamp()
            }
            //update emoji click in firebase
            this.context.addEmojiClick(emojiClick)
            .then((result) => {
                // this.setState({ message: ""});
            })
            .catch((err) => {
                alert('emoji update error');
            });    
        } else {
            alert('Chat Not Enabled Yet');
        }
    }

    render() {
        let emojiMarkup = [];

        this.state.emojis.forEach((emoji) => {
            let imgSource;
            switch(emoji.name) {
                case "h":
                    imgSource = <img id={emoji.name}
                                    src={h} 
                                    alt={emoji.name}
                                    width="28px" height="22px" />
                    break;
                case "a":
                    imgSource = <img id={emoji.name}
                                    src={a} 
                                    alt={emoji.name}
                                    width="28px" height="22px" />
                    break;
                case "b":
                    imgSource = <img id={emoji.name}
                                    src={b} 
                                    alt={emoji.name}
                                    width="28px" height="22px" />
                    break;
                case "s":
                    imgSource = <img id={emoji.name}
                                    src={s} 
                                    alt={emoji.name}
                                    width="28px" height="22px" />
                    break;
                case "w":
                    imgSource = <img id={emoji.name}
                                    src={w} 
                                    alt={emoji.name}
                                    width="28px" height="22px" />
                    break;    
                default:
                    break;
            }

            let image = <Avatar 
                            key={emoji.name}
                            variant="circle"
                            id={emoji.name}
                            style={{backgroundColor:"#ff6e40"}}
                            children={imgSource}                            
                            alt={emoji.name}
                            onClick={this.clickEmoji}
                        />

            emojiMarkup.push(<Grid item style={{display:'inline'}} key={emoji.name}> {image} </Grid>)
        })

        return (
            <Grid container 
                direction="row" 
            >
                {emojiMarkup}
            </Grid>
        )
    }
}
Emojis.contextType = FirebaseContext;

export default Emojis