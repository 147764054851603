import React, { Component, Fragment } from "react";

import axios from "axios";

//material ui components
// import { Paper, CardContent } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

//Context API
import { FirebaseContext } from "../components/Firebase";

//background image
// import imageUrl from '../images/Background-3.jpeg';
// const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/voncerts-df00d.appspot.com/o/artists%2FRanjaniGayatri%2FRanjani-Gayatri.jpeg?alt=media';

const styles = {
  card: {
    position: "relative",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  overlay: {
    position: "absolute",
    width: "30%",
    height: "4vh",
    top: "10%",
    left: "55%",
    // color: "secondary",
    // backgroundColor: '#ffffff',
    // opacity: '0.50'
  },
};

class ArtistIntro extends Component {
  state = {
    artistImage: null,
  };

  componentDidMount() {
    const userData = {
      params: {
        artistHandle: this.props.artistHandle,
      },
    };
    //fetch artist image
    axios
      .get("/artist/getArtistImage", userData)
      .then((res) => {
        this.setState({
          artistImage: res.data.aImg,
        });
      })
      .catch((err) => {
        alert("Unexpected Error : " + err);
        console.error(err);
      });
  }

  render() {
    const { classes } = this.props;
    let imageMarkup;

    if (this.state.artistImage) {
      imageMarkup = (
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={this.state.artistImage}
          ></CardMedia>
        </Card>
      );
    } else {
      imageMarkup = (
        <CircularProgress variant="indeterminate" color="secondary" size={80} />
      );
    }

    return <Fragment>{imageMarkup}</Fragment>;
  }
}
ArtistIntro.contextType = FirebaseContext;

export default withStyles(styles)(ArtistIntro);
