import React, { Component } from 'react'

//material ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    contactContainer: {
        direction: "column",
        alignItems: "center",
        justify: "center"
    },
    cardMedia: {
        height: 0,
        paddingTop: '56.25%'  
    }
};


export class contact extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container 
                className={ classes.contactContainer } 
                spacing={2}
                justify='center'
                alignItems='center'>
                <Grid item xs={12}>
                    <Card>
                        <Typography variant="h6" align="center">
                            Contact:   voncerts@gmail.com
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(contact)
