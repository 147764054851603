import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class coupon extends Component {

    render() {
        let queryString = this.props.location.search;
        let couponCode = queryString.split("=")[1];

        localStorage.setItem('pageLink', `/coupon?code=${couponCode}`);
        localStorage.setItem('coupon', couponCode);

        return (
            <Redirect to="/preview" />
        )
    }
}

export default coupon
