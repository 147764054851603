import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

//Mui imports
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { Typography } from "@material-ui/core";

const minINRPrice = 100;
const minUSDPrice = 5;
const styles = {
  card: {
    position: "relative",
  },
  cardcontent: {
    padding: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
  image: {
    height: 0,
    paddingTop: "56.25%",
  },
  imageOverlay: {
    position: "absolute",
    width: "100%",
    height: "30%",
    top: "70%",
    left: "0%",
    backgroundColor: "#ffffff",
    opacity: "0.80",
  },
  priceOptions: {
    paddingLeft: "4%",
  },
};

class Concert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceIndex: null,
      priceOpen: null,
      pTimingsIndex: -1,
    };
    this.addConcert = this.addConcert.bind(this);
    this.selectPremiere = this.selectPremiere.bind(this);
    this.selectPrice = this.selectPrice.bind(this);
    this.changePriceOpen = this.changePriceOpen.bind(this);
  }

  addConcert(event) {
    let concert;
    if (
      this.props.concert.cPriceFlag &&
      this.props.concert.cPrice.length > 1 &&
      this.state.priceIndex === null
    ) {
      alert("Please select a price");
    } else if (
      Object.keys(JSON.parse(JSON.stringify(this.props.concert.pTimings)))
        .length > 0 &&
      this.state.pTimingsIndex === -1
    ) {
      alert("Please select premiere timing");
    } else {
      //If priceflag is false set priceInput to 0
      //if priceflag is true and single pricing model and open pricing assign priceOpen
      //if priceflag is true and tiered pricing and selected price is open, assign priceOpen
      //else assign selected price
      let priceInput;
      if (!this.props.concert.cPriceFlag) {
        priceInput = 0;
      } else {
        let cPriceJSON;
        if (this.props.concert.cPrice.length === 1) {
          cPriceJSON = JSON.parse(JSON.stringify(this.props.concert.cPrice[0]));
        } else {
          cPriceJSON = JSON.parse(
            JSON.stringify(this.props.concert.cPrice[this.state.priceIndex])
          );
        }

        if (cPriceJSON.price === 0) {
          priceInput = this.state.priceOpen;
        } else {
          priceInput = cPriceJSON.price;
        }
      }

      if (Number.isInteger(priceInput)) {
        if (
          !this.props.concert.cPriceFlag ||
          (this.props.concert.cPriceFlag &&
            ((this.props.concert.cCurr === "INR" &&
              priceInput >= this.props.concert.minPrice) ||
              (this.props.concert.cCurr === "USD" &&
                priceInput >= this.props.concert.minPrice)))
        ) {
          //If pTimingsIndex is -1, then send oDate else send premiere date
          let oDate;
          if (this.state.pTimingsIndex === -1) {
            oDate = this.props.concert.oDate;
          } else {
            let timingsJSON = JSON.parse(
              JSON.stringify(this.props.concert.pTimings)
            );
            let m;
            for (m = 0; m < timingsJSON.timings.length; m++) {
              if (timingsJSON.timings[m].idx === this.state.pTimingsIndex) {
                oDate = timingsJSON.timings[m].ISOTime;
              }
            }
          }

          if (
            this.props.concert.cPriceFlag &&
            this.props.concert.cPrice.length === 1
          ) {
            concert = {
              cId: this.props.concert.cId,
              cStatus: this.props.concert.cStatus,
              oDate: oDate,
              eventName: this.props.concert.eventName,
              artistHandle: this.props.concert.artistHandle,
              cTitle: this.props.concert.cTitle,
              cImg: this.props.concert.cImg,
              liveImg: this.props.concert.liveImg,
              source: this.props.source,
              couponCode: this.props.couponCode,
              priceIndex: 0,
              priceOpen: priceInput,
              pTimingsIndex: this.state.pTimingsIndex,
            };
          } else {
            concert = {
              cId: this.props.concert.cId,
              cStatus: this.props.concert.cStatus,
              oDate: oDate,
              eventName: this.props.concert.eventName,
              artistHandle: this.props.concert.artistHandle,
              cTitle: this.props.concert.cTitle,
              cImg: this.props.concert.cImg,
              liveImg: this.props.concert.liveImg,
              source: this.props.source,
              couponCode: this.props.couponCode,
              priceIndex: this.state.priceIndex,
              priceOpen: priceInput,
              pTimingsIndex: this.state.pTimingsIndex,
            };
          }

          this.props.history.push({
            pathname: "/addToPlaylist",
            state: { concert },
          });
        } else {
          if (this.props.concert.cCurr === "INR") {
            alert(
              `Please enter a minimum price of INR ${this.props.concert.minPrice} `
            );
          } else {
            alert(
              `Please enter a minimum price of USD ${this.props.concert.minPrice} `
            );
          }
        }
      } else {
        alert("Please enter a price");
      }
    }
  }

  selectPremiere(event) {
    let val = event.target.getAttribute("data-idx");
    this.setState({
      pTimingsIndex: parseInt(val),
    });
  }

  selectPrice(event) {
    let select = document.querySelector("input[name='priceOption']:checked")
      .value;
    this.setState({
      priceIndex: select,
    });
  }

  changePriceOpen(event) {
    if (!isNaN(event.target.value)) {
      let val = event.target.value * 1;
      this.setState({
        priceOpen: val,
      });
    } else {
      event.preventDefault();
    }
  }

  render() {
    const { classes, concert } = this.props;
    const {
      cTitle,
      cStatus,
      cImg,
      liveImg,
      cPriceFlag,
      cCurr,
      cPrice,
      pTimings,
    } = concert;

    let cardImage;
    let liveText;
    if (cStatus === "Live" || cStatus === "Live-Preview") {
      cardImage = liveImg;
      liveText = "Premiere + ";
    } else {
      cardImage = cImg;
    }

    let openPriceMarkup = (
      <OutlinedInput
        value={this.state.priceOpen}
        onChange={this.changePriceOpen}
        type="tel"
        pattern="^\d+$"
        style={{ height: "30px", width: "70px", backgroundColor: "#ffffff" }}
      />
    );

    let pTimingsMarkup = [];
    let priceBText;
    let cardPriceMarkup = [];
    let cardPrice = [];
    //Generate pricing markup
    if (!cPriceFlag) {
      priceBText = (
        <Typography variant="body2">&nbsp; {cTitle} - Free </Typography>
      );
    } else {
      priceBText = <Typography variant="body2">&nbsp; {cTitle}</Typography>;
      if (cPrice.length === 1) {
        let cPriceJSON = JSON.parse(JSON.stringify(cPrice[0]));
        cardPriceMarkup.push(<hr key={0.11} />);
        if (cPriceJSON.price === 0) {
          cardPriceMarkup.push(
            <Typography key={0.12} variant="body2">
              {liveText} View for {cPriceJSON.validity} day(s) @ {cCurr}{" "}
              {openPriceMarkup} price of your choice
            </Typography>
          );
        } else {
          cardPriceMarkup.push(
            <Typography key={0.21} variant="body2">
              {liveText} View for {cPriceJSON.validity} day(s) @ {cCurr}{" "}
              {cPriceJSON.price}
            </Typography>
          );
        }
        cardPriceMarkup.push(<br key={0.13} />);
      } else {
        cardPriceMarkup.push(<hr key={0.11} />);
        let i;
        let cardPriceMarkupLine = [];
        for (i = 0; i < cPrice.length; i++) {
          let cPriceJSON = JSON.parse(JSON.stringify(cPrice[i]));
          cardPriceMarkupLine.push(
            <input
              type="radio"
              value={i}
              key={i}
              onChange={this.selectPrice}
              name="priceOption"
            />
          );
          cardPriceMarkupLine.push(<span key={i + 0.1}>&nbsp;</span>);
          if (cPriceJSON.price === 0) {
            cardPriceMarkupLine.push(
              <span key={i + 0.2}>
                &nbsp;
                {liveText} View for {cPriceJSON.validity} day(s) @ {cCurr}{" "}
                {openPriceMarkup} price of your choice
              </span>
            );
          } else {
            cardPriceMarkupLine.push(
              <span key={i + 0.2}>
                &nbsp;
                {liveText} View for {cPriceJSON.validity} day(s) @ {cCurr}{" "}
                {cPriceJSON.price}
              </span>
            );
          }
          cardPriceMarkupLine.push(<br key={i + 0.3} />);
        }
        cardPriceMarkup.push(
          <Typography key={i + 0.4} variant="body2">
            {cardPriceMarkupLine}
          </Typography>
        );
        cardPriceMarkup.push(<br key={i + 0.5} />);
      }
      if (cCurr === "INR") {
        cardPriceMarkup.push(<small key={0.22}>(India viewing only)</small>);
      }
      cardPrice.push(
        <CardContent className={classes.cardcontent} key={0.13}>
          {cardPriceMarkup}
        </CardContent>
      );
    }

    //Generate timing markup
    let pTimingsJSON = JSON.parse(JSON.stringify(pTimings));
    if ("timings" in pTimingsJSON) {
      //Populate premiere timing toggle buttons
      let timingsArray = pTimingsJSON.timings;
      let timingsGridItemMarkup = [];
      let k;
      for (k = 0; k < timingsArray.length; k++) {
        let timingsLineMarkup = [];
        let timing = JSON.parse(JSON.stringify(pTimingsJSON.timings[k]));

        if (k === this.state.pTimingsIndex) {
          timingsLineMarkup.push(
            <Card
              key={k + 0.411}
              style={{ backgroundColor: "orange", textAlign: "center" }}
              variant="outlined"
            >
              <CardActionArea
                key={k + 0.321}
                data-idx={timing.idx}
                onClick={this.selectPremiere}
              >
                {timing.date} <br />
                {timing.time} <br />
                {timing.zone}
              </CardActionArea>
            </Card>
          );
        } else {
          timingsLineMarkup.push(
            <Card
              key={k + 0.412}
              style={{ backgroundColor: "lightgrey", textAlign: "center" }}
              variant="outlined"
            >
              <CardActionArea
                key={k + 0.321}
                data-idx={timing.idx}
                onClick={this.selectPremiere}
              >
                {timing.date} <br />
                {timing.time} <br />
                {timing.zone}
              </CardActionArea>
            </Card>
          );
        }
        timingsGridItemMarkup.push(
          <Grid key={k + 0.511} item xs={4} sm={4}>
            {timingsLineMarkup}
          </Grid>
        );
      }

      //Populate premiere label
      pTimingsMarkup.push(<hr key={0.61} />);
      pTimingsMarkup.push(
        <p key={0.31} style={{ textAlign: "center" }}>
          Premiere Timings:{" "}
        </p>
      );
      pTimingsMarkup.push(
        <Grid
          key={0.711}
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {timingsGridItemMarkup}
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardActionArea onClick={this.addConcert}>
            <CardMedia
              className={classes.image}
              image={cardImage}
              title={cTitle}
            />
            <IconButton aria-label="Add to Playlist" size="small">
              <PlaylistAddIcon color="secondary" />
              <Typography variant="button" color="secondary">
                {" "}
                &nbsp; ADD &nbsp;{" "}
              </Typography>
              {priceBText}
            </IconButton>
          </CardActionArea>
          <div>{pTimingsMarkup}</div>
          <div>{cardPrice}</div>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(Concert));
