import * as app from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/remote-config';

const fbConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const tosLink = "/termsandprivacy";
const messageLimit = 500;
const emojiLimit = 500;
//configure FirebaseUI
const uiConfig = {
    // signInFlow: 'popup',
    // signInSuccessUrl: '/',
    signInOptions: [
        app.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: app.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: app.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        }
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }, 
    tosUrl: tosLink,
    privacyPolicyUrl: tosLink
};

class Firebase {
    
    constructor() {
        app.initializeApp(fbConfig);

        this.auth = app.auth();
        this.user = null;
        this.uiConfig = uiConfig;
        this.anal = app.analytics();
        this.remoteConfig = app.remoteConfig();
        this.firestore = app.firestore();
        this.fieldvalue = app.firestore.FieldValue;

        this.remoteConfig.defaultConfig = ({
            'country': 'IN',
        });

        this.claims = {
            super: false,
            admin: false,
            organizer: false,
            preview: false
        };

    }

    //Auth APIs
    setUser = (user) => {
        this.user = user;
    }

    setClaims = () => {
        this.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                if (!!idTokenResult.claims.super) {
                    this.claims.super = true;
                };
                if (!!idTokenResult.claims.admin) {
                    this.claims.admin = true;
                }; 
                if (!!idTokenResult.claims.organizer) {
                    this.claims.organizer = true;
                };
                if (!!idTokenResult.claims.preview) {
                    this.claims.preview = true;
                };
            })
            .catch((err) => {
                console.error("Error setting claims " + err);
            })
    }

    doSignOut = () => {
        this.auth.signOut();
        this.user = null;
    }

    doUserDelete = () => {
        this.auth.currentUser.delete()
        .then(() => {
            alert("Account deleted successfully. To create a new account, just re-login");
          })
          .catch((err) => {
              alert("Error while deleting account. Please contact voncerts@gmail.com");
          });      
    }

    doLoginEvent = () => {
        // console.log('log event');
        this.anal.logEvent('login', {
            'method': 'Google'
        })
    }

    eventAddToPlaylist = (params) => {
        // console.log('add to playlist ' + params);
        this.anal.logEvent("ADD_TO_PLAYLIST", params);
    }

    eventViewConcert = (params) => {
        // console.log('view concert ' + params);
        this.anal.logEvent("VIEW_CONCERT", params);
    }

    eventSongSeek = (params) => {
        this.anal.logEvent("SONG_SEEK", params);
    }

    doGetIdToken = () => {
        return this.user.getIdToken();
    }

    //check if chat is enabled
    checkChat = (cId) => {
        return this.firestore.collection('concerts').doc(cId);

    }

    //Send chat message
    sendMessage = (message) => {
        //add message
        return this.firestore.collection('chat').add(message);

    }

    //Listen for new chat messages
    getMessage = (cId) => {
        return this.firestore.collection('chat')
                .where('cId', "==", cId)
                .orderBy('cTmstmp', "desc")
                .limit(messageLimit);

            }
    
    //get emojis
    getEmojis = () => {
        return this.firestore.collection('emojis')
                    .where('status','==','open');
    }

    //Add emoji click count
    addEmojiClick = (emojiClick) => {
        //add message
        return this.firestore.collection('liveEmojis').add(emojiClick);

    }

    //Listen for new emoji clicks
    getEmojiClicks = (cId) => {
        return this.firestore.collection('liveEmojis')
                .where('cId', "==", cId)
                .orderBy('cTmstmp', "desc")
                .limit(emojiLimit);

    }
    
}

export default Firebase;