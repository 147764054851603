import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, withStyles } from '@material-ui/core';

import Player from '@vimeo/player';

//Context API
import { FirebaseContext } from '../components/Firebase';

const styles = makeStyles((theme) => ({
    videoContainer: {
        direction: "column",
        alignItems: "flex-start",
        justify: "center"
    },
    cardMedia: {
        height: 0,
        paddingTop: '56.25%'  
    }
}));

class VideoPlayer extends Component {
    state = {
        isLoaded: false
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.setState({
            isLoaded: true
        });

    }
    render() {
        const { classes, cUrl, artistHandle, cStatus, cPremiere,
                cComments, cTitle, cSongs, cViews } = this.props;
        let songsMarkup = [];
        let contentMarkup = [];
        let vUrl = cUrl;

        if (this.state.isLoaded) {
            let i;
            let iframe = document.querySelector('iframe');
            let player = new Player(iframe);
           
            if (!(cStatus === "Live" ||
                cStatus === "Live-Preview" ||
                cPremiere === true)) {
                //Set autoplay
                vUrl = vUrl + "?autoplay=1";
                if (Object.keys(cSongs).length > 0) {
                    for(i=0; i<cSongs.songs.length; i++){
                        let positionInSec = 0;
                        let posSplit = (cSongs.songs[i].sPos).split('.');
                        let posSplitLength = posSplit.length;
                        let x;
                        for (x=1; x <= posSplitLength; x++){
                            positionInSec = positionInSec + 
                                            (posSplit[posSplitLength - x] * (60 ** (x - 1) ));
                        }
                
                        songsMarkup.push(
                            <Typography key={i} align="left" variant="body2">
                                {i+1}. {cSongs.songs[i].name} - &nbsp;
                                <span style={{color: "blue", 
                                        textDecoration: "underline",
                                        cursor: "pointer"}}
                                    onClick={()=>{
                                        player.setCurrentTime(positionInSec)
                                        .then((seconds) => {
                                            // //record analytics event
                                            // const eventParams = {
                                            //     artistHandle: artistHandle,
                                            //     cTitle: cTitle,
                                            //     song: cSongs.songs[i].name
                                            // }
                                            // this.context.eventSongSeek(eventParams);
                                        })
                                        .catch((error) => {
                                            alert('Error ' + error.name);
                                        });
                                }}> 
                                    {cSongs.songs[i].sPos}
                                </span>
                            </Typography>
                        );
                    }        
                }
            }

            if (cStatus === "Live" ||
                cStatus === "Live-Preview" ||
                cPremiere) {
                // contentMarkup.push(
                //     <Typography key="1" align="left" variant='body2'>
                //         Title: {cTitle}
                //     </Typography>    
                // );
            } else {
                contentMarkup.push(
                    <Button key="1" color="primary" size="small">
                        {cViews} views
                    </Button>    
                );
                contentMarkup.push(
                    <Typography key="2" align="left" variant='body2'>
                        Title: {cTitle}
                    </Typography>
                );
                contentMarkup.push(<br key="3"/>);
                contentMarkup.push(
                    <Typography key="4" align="left" variant='body2'>
                        {cComments}
                    </Typography>
                );
                contentMarkup.push(<br key="5"/>);
            }
        }

        return (
            <Grid container 
                className={ classes.videoContainer } 
                // spacing={2}
                justify='center'
                alignItems='center'>
                <Grid item xs={12}>
                    <Card>
                        <div className="embed-container">
                            <iframe src={`${vUrl}`} frameBorder="0" title="vimeo"
                                allow="autoplay; fullscreen" allowFullScreen>
                            </iframe>
                        </div>
                        <CardContent>
                            {contentMarkup}
                            {songsMarkup}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}
VideoPlayer.contextType = FirebaseContext;

export default withStyles(styles)(VideoPlayer);