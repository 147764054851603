import React, { Component } from "react";
import axios from "axios";

//material ui
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

//Context API
import { FirebaseContext } from "../components/Firebase";

//images
const logo =
  "https://firebasestorage.googleapis.com/v0/b/voncerts-df00d.appspot.com/o/logo%2Fvoncerts-14.png?alt=media";

const openCheckout = (data, ctxt, event, history, idToken) => {
  let options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    amount: data.tPrice * 100,
    currency: data.tCurr,
    name: "Voncerts",
    description: event.eDisplayName,
    image: { logo },
    order_id: data.order_id,
    handler: function (response) {
      // console.log("response = " + response);
      // console.log("razorpay payment id = " + response.razorpay_payment_id);
      // console.log("razorpay order id = " +  response.razorpay_order_id);
      // console.log("razorpay signature = " + response.razorpay_signature);
      const userData = {
        tId: data.tId,
        tPrice: data.tPrice,
        payGate: "razorpay",
        payId: response.razorpay_payment_id,
        orderId: response.razorpay_order_id,
        signature: response.razorpay_signature,
        validity: data.validity,
      };
      const eventParams = {
        eventName: event.eventName,
        eDisplayName: event.eDisplayName,
        cCurr: data.tCurr,
        tPrice: data.tPrice,
      };
      if (typeof response.error === "undefined") {
        //verify signature and update payment details
        axios
          .post("/event/pay", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            //record analytics event
            ctxt.eventAddToPlaylist(eventParams);
            //redirect to play event
            history.replace("/playlist");
          })
          .catch((error) => {
            alert(
              "payment update error. order Id = " + response.razorpay_order_id
            );
            console.log(
              "payment update error. order Id = " + response.razorpay_order_id
            );
            history.replace(`/`);
          });
      } else {
        alert("razorpay  error : " + response.error.reason);
        console.log("razorpay error : " + response.error);
        history.replace(`/`);
      }
    },
    prefill: {
      name: ctxt.user.displayName,
      email: ctxt.user.email,
      contact: ctxt.user.phoneNumber,
    },
    notes: {
      event_Title: event.eDisplayName,
    },
    theme: {
      color: "#F37254",
    },
    modal: {
      ondismiss: function () {
        history.replace(`/events/${event.eventName}`);
      },
    },
  };
  var rzp = new window.Razorpay(options);
  rzp.open();
};

class addEventPass extends Component {
  componentDidMount() {
    //load razorpay checkout js
    const razorPayScript = document.createElement("script");
    razorPayScript.src = "https://checkout.razorpay.com/v1/checkout.js";
    razorPayScript.async = true;
    document.body.appendChild(razorPayScript);

    //fetch idToken
    let idToken = "";
    if (this.context.user) {
      this.context
        .doGetIdToken()
        .then((token) => {
          idToken = token;
          this.context.remoteConfig
            .fetchAndActivate()
            .then(() => {
              const userData = {
                country_code: this.context.remoteConfig.getString("country"),
                eventName: this.props.location.state.eventPass.eventName,
                priceIndex: this.props.location.state.eventPass.priceIndex,
                priceOpen: this.props.location.state.eventPass.priceOpen,
              };
              console.log(userData);
              //buy event pass
              return axios.post("/event/buyEventPass", userData, {
                headers: { Authorization: `Bearer ${idToken}` },
              });
            })
            .then((res) => {
              switch (res.status) {
                //201 = added to playlist
                //209 = already in playlist
                //202 = redirect to payment
                case 201:
                case 209:
                  this.props.history.replace("/playlist");
                  break;
                case 202:
                  if (res.data.payGate === "razorpay") {
                    openCheckout(
                      res.data,
                      this.context,
                      this.props.location.state.eventPass,
                      this.props.history,
                      idToken
                    );
                  } else if (res.data.payGate === "paypal") {
                    //paypal checkout for international payments
                    const product = {
                      eDisplayName: this.props.location.state.eventPass
                        .eDisplayName,
                      eStatus: this.props.location.state.eventPass.eStatus,
                      eImg: this.props.location.state.eventPass.eImg,
                      eStartDt: this.props.location.state.eventPass.eStartDt,
                      tCurr: res.data.tCurr,
                      tPrice: res.data.tPrice,
                      tId: res.data.tId,
                      order_id: res.data.order_id, //*********Added */
                      validity: res.data.validity,
                      eventName: this.props.location.state.eventPass.eventName,
                      idToken: idToken,
                    };
                    this.props.history.replace({
                      pathname: "/eventPassPaypalButton",
                      state: { product },
                    });
                  } else {
                    console.log("Unknown error");
                    alert("Unknown Error. Please try again ");
                    this.props.history.replace(
                      `/events/${this.props.location.state.eventPass.eventName}`
                    );
                  }
                  break;
                default:
                  break;
              }
              // console.log(res);
            })
            .catch((err) => {
              console.log("Unexpected error : " + err);
              alert("Unexpected Error. Please try again ");
              this.props.history.replace(
                `/events/${this.props.location.state.eventPass.eventName}`
              );
            });
        })
        .catch((err) => {
          alert("Session expired. Please login : " + err);
          this.props.history.push("/login");
        });
    } else {
      alert("Session expired. Please login");
      this.props.history.push("/login");
    }
  }

  render() {
    const { eventPass } = this.props.location.state;
    const {
      cId,
      oDate,
      artistHandle,
      cTitle,
      cImg,
      liveImg,
      source,
      couponCode,
      priceIndex,
    } = eventPass;
    const { classes } = this.props;

    return (
      <Grid
        container
        // className={ classes.gridContainer }
        spacing={2}
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      </Grid>
    );
  }
}
addEventPass.contextType = FirebaseContext;

export default addEventPass;
