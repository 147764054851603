import React from "react";

import {
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";

const itemDate = (cTmstmp) => {
  if (cTmstmp !== "") {
    let msgDate = new Date(cTmstmp);
    return (
      msgDate.getFullYear() +
      "-" +
      ("0" + (msgDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + msgDate.getDate()).slice(-2) +
      " " +
      ("0" + msgDate.getHours()).slice(-2) +
      ":" +
      ("0" + msgDate.getMinutes()).slice(-2)
    );
  } else {
    return;
  }
};

const ListItemFunction = ({ index, style, aSrc, dName, msg, cTmstmp }) => (
  <div style={style} key={index}>
    <ListItem alignItems="flex-start" style={{ width: "90%" }}>
      <ListItemAvatar>
        <Avatar alt="U" src={aSrc} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography component="span" variant="subtitle2">
              {dName} - {itemDate(cTmstmp)}
            </Typography>
          </React.Fragment>
        }
        style={{ wordWrap: "break-word" }}
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2">
              {msg}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  </div>
);

export default ListItemFunction;
