import React, { Component } from 'react'

//ui imports
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import withStyles from '@material-ui/core/styles/withStyles';

//Context API
import { FirebaseContext } from '../components/Firebase';

const charLimit = 100;
const styles = {
    base: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        spacing: 1
    },
    textField: {
        width: '100%',
    }
}

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: 0,
            message: "",
            errorText: ""
        };

        this.changeMessage = this.changeMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        //setup listener for sending message
        let docRef = this.context.checkChat(this.props.cId);
        this.unsubscribe = docRef.onSnapshot((doc) => {
            if (doc.data().liveStatus === "Start") {
                this.setState({ enabled: 1 });
            } else {
                this.setState({ enabled: 0 });
            }
        });
    }

    componentWillUnmount() {
        //remove listener for new messages
        this.unsubscribe && this.unsubscribe();
    }

    changeMessage(event) {
        let msg = event.target.value;

        //Restrict message length
        if (msg.length <= charLimit) {
            this.setState({ message: event.target.value,
                            errorText: "" });
        } else {
            event.preventDefault();
            this.setState({ errorText: `${charLimit} Chars limit` });
        }
    }

    sendMessage(event) {
        event.preventDefault();

        if (this.state.enabled === 1) {
            //prepare add message
            let nameDisplay;
            if (this.context.user.displayName === null) {
                nameDisplay = this.context.user.email.slice(0, this.context.user.email.search('@'));
            } else {
                nameDisplay = this.context.user.displayName
            }
            
            let updateTimeString = new Date().toISOString();
            let message = {
                cId: this.props.cId,
                displayName: nameDisplay,
                photoUrl: this.context.user.photoURL,
                message: this.state.message,
                uId: this.context.user.uid,
                cTmstmp: updateTimeString,
                // cTmstmp: this.context.fieldvalue.serverTimestamp()
            };

            //write to firestore
            if (this.state.message !== "") {
                this.context.sendMessage(message)
                .then((doc) => {
                    this.setState({ message: ""});
                })
                .catch((err) => {
                    this.setState({ errorText: "Error sending message"});
                });
            }
        } else {
            this.setState({ errorText: "Chat Not Enabled Yet" });
        }

    }

    render() {
        const { classes, cId } = this.props;
        return (
            <FormControl className={ classes.margin, classes.textField }
                            variant="outlined">
                {/* <InputLabel htmlFor="chat-message">Type message</InputLabel> */}
                <OutlinedInput
                id="chat-message"
                placeholder="Comment"
                value={this.state.message}
                multiline
                rowsMax={1}
                style={{height: "15px", backgroundColor:"#ffffff"}}
                onChange={this.changeMessage}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="Send"
                            onClick={this.sendMessage}
                            edge="end"
                        >
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>
                }
                // labelWidth={120}
                />
                <FormHelperText id="error-text" error={true} style={{fontSize:"15px"}}>
                    {this.state.errorText}
                </FormHelperText>
          </FormControl>
        )
    }
}
Message.contextType = FirebaseContext;

export default withStyles(styles)(Message);
