import React, { Component, Fragment } from 'react';
import DocumentMeta from 'react-document-meta';

//material ui
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import Message from './Message';
import MessageList from './MessageList';

const styles = {
    commentsContainer: {
        direction: "column",
        alignItems: "center",
        justify: "center"
    }
}

class Comments extends Component {
    componentDidMount(){
        if(window.FB){
            window.FB.XFBML.parse();
        }

        window.fbAsyncInit = function() {
            window.FB.init({
                appId: this.props.cFBAppId,
                cookie: true,
                xfbml: true,
                version: 'v7.0'
            });
        }.bind(this);

        //Load the sdk synchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    render(){
        const meta = {
            title: "voncerts.com",
            meta: {
                property: {
                    'og:url': window.location.href,
                    'og:type': 'website',
                    'og:title': this.props.cTitle,
                    'og:description': this.props.cOGDesc,
                    'og:image': this.props.cImg,
                    'fb:app_id': this.props.cFBAppId    
                }
            }
        };

        let commentsMarkup = [];
        let messageMarkup = [];
        let messageListMarkup = [];
        let itemMarkup = [];
        let commentsCId = ["2BcwvwXO27ED3g10RSq0",
                            "qZhYSePeuHnX4mS4GBLU",
                            "57bNGrIUpBhm74U9qvrv",
                            "3Zv3zwhGhANw1067H7XK",
                            "WdmemMgeUoAmHRfusRL1",
                            "nRYEStr095Bw7x6xfvxB",
                            "f5yQ6mA1IiEFH8oEhs6G",
                            "F0ufAgafj89HmD5ElUGt",
                            "SVMlE8yi14rZErV0LRWw",
                            "EtlGQ2xFQcju5TnTeGjP",
                        ];
        if (commentsCId.includes(this.props.cId)) {
            commentsMarkup.push(<Grid item xs={12}><div className="fb-comments" 
                            data-href={window.location.href} 
                            data-numposts="5" data-width="100%">
                            </div></Grid>
                        );
        } else {
            messageMarkup.push(<Message cId={this.props.cId} />);
            messageListMarkup.push(<MessageList cId={this.props.cId} />);
            itemMarkup.push(<Grid item xs={12}> {messageMarkup} </Grid>);
            itemMarkup.push(<Grid item xs={12}> {messageListMarkup} </Grid>)
            commentsMarkup.push(<Grid container direction="column"> {itemMarkup} </Grid>);
        }

        return (
            <Fragment>
                <DocumentMeta {...meta} />
                <Grid container 
                        direction="column"         
                        justify="flex-start" 
                        alignItems="flex-start">
                    {/* <Grid item xs={12}>&nbsp;</Grid> */}
                    <Grid item xs={12}>
                        <div className="fb-like" 
                            data-href={window.location.href} 
                            data-width="" data-layout="button_count" data-action="like" 
                            data-size="small" data-share="true">
                        </div>
                    </Grid>
                    {commentsMarkup}
                </Grid>
            </Fragment>
        );
    }
}

export default withStyles(styles)(Comments)