import React, { Component, Fragment } from "react";
import axios from "axios";

//material ui components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import withStyles from "@material-ui/core/styles/withStyles";

//Context API
import { FirebaseContext } from "../components/Firebase";

//Components
import Concert from "../components/Concert";
import EventIntro from "../components/EventIntro";
import EventPass from "../components/EventPass";
import Announcements from '../components/Announcements';

//images
// const nextImgUrl = '';

const styles = {
  gridColumn: {
    direction: "column",
    // alignItems: "center",
    // justify: "center",
    // spacing: 2
  },
  gridPosterRow: {
    direction: "row",
    justify: "center",
    alignItems: "flex-start",
    alignContent: "center",
  },
  gridRow: {
    direction: "row",
    alignItems: "flex-start",
    justify: "flex-start",
    // spacing: 4
  },
  concertList: {
    display: "flex",
  },
  sectionTitle: {
    // background: "#262626",
    background: "#f5f5f5",
    align: "center",
    display: "block",
    paragraph: true,
  },
  defaultMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
};

class event extends Component {
  state = {
    concerts: null,
    eventSeason: null,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    let userData;

    this.context.remoteConfig
      .fetchAndActivate()
      .then(() => {
        userData = {
          params: {
            country_code: this.context.remoteConfig.getString("country"),
            event: this.props.match.params.eventName,
          },
        };
        //fetch open concerts
        return axios.get("/event/concerts", userData);
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          concerts: res.data,
        });
        //fetch event season pass
        return axios.get("/event/getEventSeason", userData);
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          eventSeason: res.data,
        });
      })
      .catch((err) => {
        alert("Unexpected Error : " + err);
        console.log(err);
        this.props.history.replace("/");
      });
  }

  render() {
    const { classes } = this.props;
    let concertsMarkup = [];
    let eventIntroMarkup = [];
    let eventPassMarkup = [];

    //Load Event Intro
    if (this.state.eventSeason) {
      eventIntroMarkup.push(
        <EventIntro eImg={this.state.eventSeason[0].eImg} />
      );
    } else {
      eventIntroMarkup.push(
        <Grid key={0} item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      );
    }

    //Load Event season pass
    if (this.state.eventSeason) {
      eventPassMarkup.push(
        <EventPass
          event={this.state.eventSeason[0]}
          source="event"
          couponCode=""
        />
      );
    } else {
      eventPassMarkup.push(
        <Grid key={0} item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      );
    }

    //Load event concerts
    if (this.state.concerts) {
      concertsMarkup.push(
        this.state.concerts.map((concert) => (
          <Grid item xs={12} sm={3}>
            <Grid container className={classes.gridRow} spacing={1}>
              <Concert
                key={concert.cId}
                concert={concert}
                source="event"
                couponCode=""
              />
            </Grid>
          </Grid>
        ))
      );
    } else {
      concertsMarkup.push(
        <Grid key={0} item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      );
    }

    return (
      <Fragment>
        <Announcements target={this.props.match.params.eventName}/>
        <Grid container className={classes.gridColumn} spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.gridPosterRow}
              justify="center"
              spacing={1}
            >
              <Grid item xs={12} sm={9}>
                {eventIntroMarkup}
              </Grid>
              <Grid item xs={12} sm={3}>
                {eventPassMarkup}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className={classes.gridRow} spacing={1}>
              {concertsMarkup}
            </Grid>
          </Grid>
        </Grid>

      </Fragment>
    );
  }
}
event.contextType = FirebaseContext;

export default withStyles(styles)(event);
