import React, { Component } from "react";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

//Context API
import { FirebaseContext } from "../components/Firebase";

const styles = {
  // root: {
  //     flexGrow: 1
  // },
  gridContainer: {
    direction: "column",
    spacing: 4,
    justify: "center",
    alignItems: "center",
  },
};

class admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminInput: "",
      organizerInput: "",
      previewInput: "",
      paymentInput: "",
      extendInput: "",
      checkPlInput: "",
      emailAllInput: "",
      addDocumentInput: "",
      delayedPayInput: "",
      removePreviewInput: "",
      queryCollectionInput: "",
    };

    this.changeAdmin = this.changeAdmin.bind(this);
    this.changeOrganizer = this.changeOrganizer.bind(this);
    this.changePreview = this.changePreview.bind(this);
    this.changePayment = this.changePayment.bind(this);
    this.changeExtension = this.changeExtension.bind(this);
    this.changeCheckPl = this.changeCheckPl.bind(this);
    this.changeEmailAll = this.changeEmailAll.bind(this);
    this.changeAddDocument = this.changeAddDocument.bind(this);
    this.changeDelayedPay = this.changeDelayedPay.bind(this);
    this.changeRemovePreview = this.changeRemovePreview.bind(this);
    this.changeQueryCollection = this.changeQueryCollection.bind(this);

    this.submitAdmin = this.submitAdmin.bind(this);
    this.submitOrganizer = this.submitOrganizer.bind(this);
    this.submitPreview = this.submitPreview.bind(this);
    this.recordPayment = this.recordPayment.bind(this);
    this.extendPlaylist = this.extendPlaylist.bind(this);
    this.checkPl = this.checkPl.bind(this);
    this.submitEmailAll = this.submitEmailAll.bind(this);
    this.addDocument = this.addDocument.bind(this);
    this.recordDelayedPay = this.recordDelayedPay.bind(this);
    this.removePreview = this.removePreview.bind(this);
    this.queryCollection = this.queryCollection.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof this.context.user === "object" && this.context.user !== null) {
      if (
        !(
          this.context.claims.super ||
          this.context.claims.admin ||
          this.context.claims.organizer
        )
      ) {
        this.props.history.replace("/");
      }
    } else {
      this.props.history.replace("/");
    }
  }

  submitAdmin(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('super claims : ' + this.context.claims.super);

          const userData = JSON.parse(this.state.adminInput);
          console.log(userData);
          return axios.post("/access/setAdmin", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err.message);
          alert(err.message);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  submitOrganizer(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('admin claims : ' + this.context.claims.admin);

          const userData = JSON.parse(this.state.organizerInput);
          console.log(userData);
          return axios.post("/access/addOrganizer", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err.message);
          alert(err.message);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  submitPreview(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.previewInput);
          console.log(userData);
          return axios.post("/access/addForPreview", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  recordPayment(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.paymentInput);
          console.log(userData);
          return axios.post("/playlist/manualPayment", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  extendPlaylist(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.extendInput);
          console.log(userData);
          return axios.post("/playlist/extendValidity", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  checkPl(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.checkPlInput);
          console.log(userData);
          return axios.post("/access/checkPlaylist", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + JSON.stringify(res.data));
          alert("response obtained");
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  submitEmailAll(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          const userData = JSON.parse(this.state.emailAllInput);
          console.log(userData);
          return axios.post("/emails/emailallusers", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  addDocument(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.addDocumentInput);
          console.log(userData);
          return axios.post("/documents/add", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + JSON.stringify(res.data));
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  recordDelayedPay(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.delayedPayInput);
          console.log(userData);
          return axios.post("/event/payDelayed", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + JSON.stringify(res.data));
          alert("response obtained");
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  removePreview(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.removePreviewInput);
          console.log(userData);
          return axios.post("/access/removeFromPreview", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + res.data.message);
          alert(res.data.message);
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  queryCollection(event) {
    event.preventDefault();
    if (typeof this.context.user === "object" && this.context.user !== null) {
      this.context
        .doGetIdToken()
        .then((idToken) => {
          // console.log('token : ' + idToken);
          // console.log('organizer claims : ' + this.context.claims.organizer);

          const userData = JSON.parse(this.state.queryCollectionInput);
          console.log(userData);
          return axios.post("/documents/queryCollection", userData, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
        })
        .then((res) => {
          console.log("response " + JSON.stringify(res.data.message));
          alert(JSON.stringify(res.data.message));
        })
        .catch((err) => {
          console.error(err);
          alert(err);
          this.props.history.replace("/");
        });
    } else {
      this.props.history.replace("/");
    }
  }

  changeAdmin(event) {
    this.setState({ adminInput: event.target.value });
  }

  changeOrganizer(event) {
    this.setState({ organizerInput: event.target.value });
  }

  changePreview(event) {
    this.setState({ previewInput: event.target.value });
  }

  changePayment(event) {
    this.setState({ paymentInput: event.target.value });
  }

  changeExtension(event) {
    this.setState({ extendInput: event.target.value });
  }

  changeCheckPl(event) {
    this.setState({ checkPlInput: event.target.value });
  }

  changeEmailAll(event) {
    this.setState({ emailAllInput: event.target.value });
  }

  changeAddDocument(event) {
    this.setState({ addDocumentInput: event.target.value });
  }

  changeDelayedPay(event) {
    this.setState({ delayedPayInput: event.target.value });
  }

  changeRemovePreview(event) {
    this.setState({ removePreviewInput: event.target.value });
  }

  changeQueryCollection(event) {
    this.setState({ queryCollectionInput: event.target.value });
  }

  render() {
    const { classes } = this.props;
    let adminMarkup;
    let organizerMarkup;
    let previewMarkup;
    let paymentMarkup;
    let extensionMarkup;
    let checkPlMarkup;
    let defaultMarkup;
    let emailMarkup;
    let documentMarkup;
    let delayedPayMarkup;
    let removePreviewMarkup;
    let queryCollectionMarkup;

    if (this.context.claims.super) {
      //show submitAdmin form
      adminMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.submitAdmin}>
            <input
              type="text"
              value={this.state.adminInput}
              onChange={this.changeAdmin}
            />
            <input type="submit" value="Add Admin" />
          </form>
        </Grid>
      );
      //show submitOrganizer form
      organizerMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.submitOrganizer}>
            <input
              type="text"
              value={this.state.organizerInput}
              onChange={this.changeOrganizer}
            />
            <input type="submit" value="Add Organizer" />
          </form>
        </Grid>
      );
    } else if (this.context.claims.admin) {
      //show submitOrganizer form
      organizerMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.submitOrganizer}>
            <input
              type="text"
              value={this.state.organizerInput}
              onChange={this.changeOrganizer}
            />
            <input type="submit" value="Add Organizer" />
          </form>
        </Grid>
      );
      //show send communication form
      emailMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.submitEmailAll}>
            <input
              type="text"
              value={this.state.emailAllInput}
              onChange={this.changeEmailAll}
            />
            <input type="submit" value="Email All" />
          </form>
        </Grid>
      );
      //show query collection form
      queryCollectionMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.queryCollection}>
            <input
              type="text"
              value={this.state.queryCollectionInput}
              onChange={this.changeQueryCollection}
            />
            <input type="submit" value="Query Collection" />
          </form>
        </Grid>
      );
    } else if (this.context.claims.organizer) {
      //show submitPreview form
      previewMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.submitPreview}>
            <input
              type="text"
              value={this.state.previewInput}
              onChange={this.changePreview}
            />
            <input type="submit" value="Add Previewers" />
          </form>
        </Grid>
      );
      //show record payment form
      paymentMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.recordPayment}>
            <input
              type="text"
              value={this.state.paymentInput}
              onChange={this.changePayment}
            />
            <input type="submit" value="Record Payment" />
          </form>
        </Grid>
      );
      //show extension form
      extensionMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.extendPlaylist}>
            <input
              type="text"
              value={this.state.extendInput}
              onChange={this.changeExtension}
            />
            <input type="submit" value="Extend Playlist" />
          </form>
        </Grid>
      );
      //show check playlist form
      checkPlMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.checkPl}>
            <input
              type="text"
              value={this.state.checkPlInput}
              onChange={this.changeCheckPl}
            />
            <input type="submit" value="Check Playlist" />
          </form>
        </Grid>
      );
      //add documents
      documentMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.addDocument}>
            <input
              type="text"
              value={this.state.addDocumentInput}
              onChange={this.changeAddDocument}
            />
            <input type="submit" value="Add Document" />
          </form>
        </Grid>
      );
      //record delayed payments
      delayedPayMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.recordDelayedPay}>
            <input
              type="text"
              value={this.state.delayedPayInput}
              onChange={this.changeDelayedPay}
            />
            <input type="submit" value="Record delayed payment" />
          </form>
        </Grid>
      );
      //show removePreview form
      removePreviewMarkup = (
        <Grid item xs={12}>
          <form onSubmit={this.removePreview}>
            <input
              type="text"
              value={this.state.removePreviewInput}
              onChange={this.changeRemovePreview}
            />
            <input type="submit" value="Remove Previewers" />
          </form>
        </Grid>
      );
    } else {
      defaultMarkup = (
        <Grid item xs={12}>
          <Typography>&nbsp;</Typography>
          <Typography variant="h6" align="center">
            You do not have permissions to access this page.
          </Typography>
          <Typography>&nbsp;</Typography>
          <Typography variant="h6" align="center">
            Please contact voncerts@gmail.com if you need access
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        direction="column"
        spacing={4}
        justify="center"
        alignItems="center"
      >
        &nbsp;
        {defaultMarkup}
        {adminMarkup}
        {organizerMarkup}
        {previewMarkup}
        {paymentMarkup}
        {extensionMarkup}
        {checkPlMarkup}
        {emailMarkup}
        {documentMarkup}
        {delayedPayMarkup}
        {removePreviewMarkup}
        {queryCollectionMarkup}
      </Grid>
    );
  }
}
admin.contextType = FirebaseContext;

export default withStyles(styles)(admin);
