import React, { Component, Fragment } from "react";
import axios from "axios";

//material ui components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import withStyles from "@material-ui/core/styles/withStyles";

//Context API
import { FirebaseContext } from "../components/Firebase";

//Components
import Concert from "../components/Concert";
import ArtistIntro from "../components/ArtistIntro";
import Announcements from "../components/Announcements";

//images
// const nextImgUrl = '';

const styles = {
  gridColumn: {
    direction: "column",
    // alignItems: "center",
    // justify: "center",
    // spacing: 2
  },
  gridRow: {
    direction: "row",
    alignItems: "flex-start",
    justify: "flex-start",
    // spacing: 4
  },
  concertList: {
    display: "flex",
  },
  sectionTitle: {
    // background: "#262626",
    background: "#f5f5f5",
    align: "center",
    display: "block",
    paragraph: true,
  },
  defaultMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
};

class artist extends Component {
  state = {
    concerts: null,
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    this.context.remoteConfig
      .fetchAndActivate()
      .then(() => {
        const userData = {
          params: {
            country_code: this.context.remoteConfig.getString("country"),
            artist: this.props.match.params.artistHandle,
          },
        };
        //fetch open concerts
        return axios.get("/artist/concerts", userData);
      })
      .then((res) => {
        // console.log(res.data);
        this.setState({
          concerts: res.data,
        });
      })
      .catch((err) => {
        alert("Unexpected Error : " + err);
        console.log(err);
        this.props.history.replace("/");
      });
  }

  render() {
    const { classes } = this.props;
    let concertsMarkup = [];

    if (this.state.concerts) {
      concertsMarkup.push(
        this.state.concerts.map((concert) => (
          <Concert
            key={concert.cId}
            concert={concert}
            source="artist"
            couponCode=""
          />
        ))
      );
    } else {
      concertsMarkup.push(
        <Grid key={0} item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      );
    }

    return (
      <Fragment>
        <Announcements target={this.props.match.params.artistHandle} />
        <Grid
          container
          className={classes.gridRow}
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sm={9}>
            <ArtistIntro artistHandle={this.props.match.params.artistHandle} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container className={classes.gridColumn} spacing={1}>
              {concertsMarkup}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
artist.contextType = FirebaseContext;

export default withStyles(styles)(artist);
