import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

//Authentication
import { FirebaseContext } from "./components/Firebase";
import { AuthUserContext } from "./components/Session";

//Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import ErrorBoundaryHelper from "./errorBoundaryHelper";

//Pages
import home from "./pages/home";
import artist from "./pages/artist";
import event from "./pages/event";
import login from "./pages/login";
import admin from "./pages/admin";
import preview from "./pages/preview";
import coupon from "./pages/coupon";
import playlist from "./pages/playlist";
import playConcert from "./pages/playConcert";
import addToPlaylist from "./pages/addToPlaylist";
import addEventPass from "./pages/addEventPass";
import paypalButton from "./pages/paypalButton";
import eventPassPaypalButton from "./pages/eventPassPaypalButton";
import NoMatch from "./pages/NoMatch";
import contact from "./pages/contact";
import termsandprivacy from "./pages/termsAndPrivacy";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#484848",
      main: "#212121",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ffa06d",
      main: "#ff6e40",
      dark: "#c53d13",
      contrastText: "#000000",
    },
  },
  typography: {
    useNextVariants: true,
    // h5: {
    //   fontSize: '1.5rem'
    // },
    // subtitle2: {
    //   fontSize: '0.75rem'
    // },
    caption: {
      fontSize: "0.25rem",
    },
  },
  props: {
    MuiCardHeader: {
      subheaderTypographyProps: {
        fontSize: "0.25rem",
      },
    },
  },
});

theme = responsiveFontSizes(theme);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
    };
  }
  componentDidMount() {
    this.unregisterAuthObserver = this.context.auth.onAuthStateChanged(
      (authUser) => {
        // authUser ? this.setState({authUser}) : this.setState({authUser: null});
        // authUser ? (this.context.setUser(authUser)) : (this.context.setUser(null));
        if (authUser) {
          this.setState({ authUser });
          this.context.setUser(authUser);
          this.context.setClaims();
        } else {
          this.setState({ authUser: null });
          this.context.setUser(null);
        }
      }
    );
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  render() {
    return (
      <AuthUserContext.Provider value={this.state}>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Router>
              <Navbar />
              <div className="container">
                <ErrorBoundaryHelper>
                  <Switch>
                    <Route exact path="/" component={home} />
                    <Route
                      exact
                      path="/artists/:artistHandle"
                      component={artist}
                    />
                    <Route exact path="/events/:eventName" component={event} />
                    <Route exact path="/login" component={login} />
                    <Route exact path="/admin" component={admin} />
                    <Route exact path="/preview" component={preview} />
                    <Route exact path="/coupon" component={coupon} />
                    <Route
                      exact
                      path="/termsandprivacy"
                      component={termsandprivacy}
                    />
                    <Route exact path="/contact" component={contact} />
                    <Route exact path="/playlist" component={playlist} />
                    <Route
                      exact
                      path="/artists/:artistHandle/:concertHandle"
                      component={playConcert}
                    />
                    <Route
                      exact
                      path="/addToPlaylist"
                      component={addToPlaylist}
                    />
                    <Route
                      exact
                      path="/addEventPass"
                      component={addEventPass}
                    />
                    <Route
                      exact
                      path="/paypalButton"
                      component={paypalButton}
                    />
                    <Route
                      exact
                      path="/eventPassPaypalButton"
                      component={eventPassPaypalButton}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                </ErrorBoundaryHelper>
              </div>
            </Router>
          </div>
          <Footer />
        </MuiThemeProvider>
      </AuthUserContext.Provider>
    );
  }
}
App.contextType = FirebaseContext;

export default App;
