import React, { Component, createContext, Fragment } from 'react'

//Context API
import { FirebaseContext } from '../components/Firebase';

import aha from '../emojis/aha.png';
import bale from '../emojis/bale.png';
import heart from '../emojis/heart-3.png';
import sabaash from '../emojis/sabaash-4.png';
import wah from '../emojis/wah.png';

export const EmojiContext = createContext();

const emojiClicks = [];
const maxEmojis = 10;
let initialLoad = true;
let animReqID;

let requestAnimationFrame = window.requestAnimationFrame       ||
                            window.webkitRequestAnimationFrame ||
                            window.mozRequestAnimationFrame ||
                            window.msRequestAnimationFrame ||
                            function( callback ){
                                return window.setTimeout(callback, 1000 / 60);
                            };

let cancelAnimationFrame = window.cancelAnimationFrame ||
                            window.webkitCancelAnimationFrame ||
                            window.mozCancelAnimationFrame ||
                            window.msCancelAnimationFrame ||
                            function(reqId){clearTimeout(reqId)};

class EmojiWindow extends Component {

    componentDidMount(){
        //set emoji click listener
        let docRef = this.context.getEmojiClicks(this.props.cId);
        this.unsubscribe = docRef.onSnapshot((snapshot) => {
            let emoji;
            //ignore first time emojis
            // console.log('initialload = ' + initialLoad);
            if (!initialLoad) {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        emoji = {
                            name: change.doc.data().name
                        }
                        emojiClicks.push(emoji);
                    }
                })
            } else {
                initialLoad = false;
            }
        })

        //start emoji animation
        animReqID = requestAnimationFrame(emojiAnimate)
    }

    componentWillUnmount() {
        //remove firestore listener for emojis
        this.unsubscribe && this.unsubscribe();

        initialLoad = true;
        //cancel animation
        cancelAnimationFrame(animReqID);

    }

    render() {
        let ahaMarkup = [];
        let baleMarkup = [];
        let heartMarkup = [];
        let sabaashMarkup = [];
        let wahMarkup = [];
        let i;

        for (i=0; i<maxEmojis; i++) {
            let aId = "a" + i;
            ahaMarkup.push(<img key={aId} id={aId} className="a" src={aha} 
                            width="28px" height="22px" alt="A"
                            style={{ display:"none", position:"absolute", top:"30%", left:"82%"}} />);
            let bId = "b" + i;
            baleMarkup.push(<img key={bId} id={bId} className="b" src={bale} 
                            width="32px" height="22px" alt="B" 
                            style={{ display:"none", position:"absolute", top:"30%", left:"79%"}} />);
            let hId = "h" + i;
            heartMarkup.push(<img key={hId} id={hId} className="h" src={heart} 
                            width="22px" height="22px" alt="H" 
                            style={{ display:"none", position:"absolute", top:"30%", left:"76%"}} />);
            let sId = "s" + i;
            sabaashMarkup.push(<img key={sId} id={sId} className="s" src={sabaash} 
                                width="62px" height="22px" alt="S"
                                style={{ display:"none", position:"absolute", top:"30%", left:"70%"}} />);
            let wId = "w" + i;
            wahMarkup.push(<img key={wId} id={wId} className="w" src={wah} 
                            width="32px" height="22px" alt="W" 
                            style={{ display:"none", position:"absolute", top:"30%", left:"73%"}} />);
        }

        return (
            //mount emojis 
            <div style={{position:"absolute", top:"0%", left:"60%", height:"500px", width:"30%" }}>
                {ahaMarkup}
                {baleMarkup}
                {heartMarkup}
                {sabaashMarkup}
                {wahMarkup}
            </div>
        )
    }
}
EmojiWindow.contextType = FirebaseContext;

export default EmojiWindow

//Function for emoji animation
function emojiAnimate () {
    //Check for new emojiclicks
    let emojiClick;
    if (emojiClicks.length > 0) {
        emojiClick = emojiClicks[0].name;
        emojiClicks.splice(0,1);
    } else {
        emojiClick = "";
    }

    translateEmoji("a", emojiClick)
    translateEmoji("b", emojiClick)
    translateEmoji("h", emojiClick)
    translateEmoji("s", emojiClick)
    translateEmoji("w", emojiClick)
    
    animReqID = requestAnimationFrame(emojiAnimate);
}

function translateEmoji(emojiClassName, emojiClick) {
    let elems = document.getElementsByClassName(emojiClassName);

    let i;
    for (i=0;i<elems.length;i++) {
        //if element is visible and emoji is at end point, make emoji invisible
        //if element is visible and emoji is not yet in end point, then translateY
        //if element is not visible and if emojiClick is same emoji, then
        //  make emoji visible at starting point
        //  clear emojiClick
        //if all emojis are visible when a new click comes, repurpose the last emoji for new click
        //
        let topPercent = parseFloat(elems[i].style.top.split("%"));
        if (emojiClassName === emojiClick &&
                i === (elems.length - 1)) {
            elems[i].style.top = "30%"
            elems[i].style.display = 'block';
            emojiClick = "";
        } else if (elems[i].style.display !== "none" &&
                    topPercent < 5 ) {
            elems[i].style.display = 'none';
        } else if (elems[i].style.display !== "none" &&
                    topPercent >= 5 ) {
            elems[i].style.top = (topPercent - 0.2) + "%";
        } else if (elems[i].style.display === 'none' &&
                    emojiClassName === emojiClick) {
            elems[i].style.display = 'block';
            elems[i].style.top = "30%";
            emojiClick = "";
        }       
    }
}
