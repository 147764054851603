import React, { Component, Fragment } from "react";

//material ui components
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import withStyles from "@material-ui/core/styles/withStyles";

//Context API
import { FirebaseContext } from "./Firebase";

const styles = {
  card: {
    position: "relative",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  overlay: {
    position: "absolute",
    width: "30%",
    height: "4vh",
    top: "10%",
    left: "55%",
    // color: "secondary",
    // backgroundColor: '#ffffff',
    // opacity: '0.50'
  },
};

class EventIntro extends Component {

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={this.props.eImg}
          ></CardMedia>
        </Card>
      </Fragment>
    );
  }
}
EventIntro.contextType = FirebaseContext;

export default withStyles(styles)(EventIntro);
