import React, { Component } from 'react';

//Styles
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    footerCss: {
        flexShrink: '0',
        textAlign: 'center',
        // color: "#ffffff",
        marginTop: 'auto',
        position: 'relative',
        bottom: 0,
    }
};

class Footer extends Component {

    render() {
        const { classes } = this.props;

        return (
            <footer className={ classes.footerCss }>
                {/* <div><p>{copyrightMarkup}</p></div> */}
                <br/><br/>
                <div><p>&copy; VONCERTS</p></div>
                <a href="/termsandprivacy" >
                    Terms and Privacy Policy</a>
                &emsp;&emsp;
                <a href="/contact">Contact</a>
            </footer>
        )
    }
}

export default withStyles(styles)(Footer) 