import React, { Component, Fragment } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseContext } from '../components/Firebase';
import { AuthUserContext } from '../components/Session';
import { Redirect } from 'react-router-dom';

//material ui
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';


const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        flexGrow: 1,
    },
};

class login extends Component {

    render() {
        const { classes } = this.props;

        return (
            <AuthUserContext.Consumer>
            { context => (
                <Fragment>
                <Paper className={ classes.root } square>
                    { !context.authUser && 
                        <Fragment >
                        <Typography variant="body1">&nbsp;</Typography>
                        <StyledFirebaseAuth uiConfig={this.context.uiConfig} firebaseAuth={this.context.auth}/>
                        </Fragment>
                    }
                    { context.authUser && localStorage.getItem('pageLink') &&
                        <Redirect to={localStorage.getItem('pageLink')} />}
                    { context.authUser && !localStorage.getItem('pageLink') &&
                        <Redirect to='/' />}
                </Paper>
                </Fragment>                
                )
            }
            </AuthUserContext.Consumer>
        );
    }
}
login.contextType = FirebaseContext;

export default withStyles(styles)(login)
