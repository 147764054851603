import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

//Mui imports
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  card: {
    position: "relative",
  },
  artistMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
  artistOverlay: {
    position: "absolute",
    width: "100%",
    height: "30%",
    top: "70%",
    left: "0%",
    backgroundColor: "#ffffff",
    opacity: "0.60",
  },
};

class Artist extends Component {
  render() {
    const { classes, artist } = this.props;
    const { artistHandle, aDisplayName, aImg } = artist;

    var toLink = `/artists/${artistHandle}`;

    return (
      <Grid item xs={12} sm={3}>
        <Card className={classes.card}>
          <CardActionArea>
            <Link
              to={{
                pathname: `/artists/${artistHandle}`,
                state: { aImg },
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <CardMedia
                className={classes.artistMedia}
                image={aImg}
              ></CardMedia>
              <CardContent className={classes.artistOverlay}>
                <Typography variant="subtitle1">{aDisplayName}</Typography>
              </CardContent>
            </Link>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(Artist);
