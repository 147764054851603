import { Typography, Button, Grid } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class NoMatch extends Component {
  render() {
    return (
      <Fragment>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Sorry!
            </Typography>
            <Typography variant="h5" align="center">
              Network error
            </Typography>
            <Typography variant="h5" align="center">
              Please click the button to start over
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/"
            >
              Go to Home Page
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default NoMatch;
