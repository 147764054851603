import React, { Component } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

//Context API
// import { AuthUserContext } from '../components/Session';
import { FirebaseContext } from '../components/Firebase';

//Components
import PlaylistItem from '../components/PlaylistItem';
import { Typography } from '@material-ui/core';

const styles = {
    // root: {
    //     flexGrow: 1
    // },
    gridContainer: {
        spacing: 4,
        justify: 'center',
        alignItems: 'center'
    }
}

class playlist extends Component {
    state = {
        playlistItems: null,
        playlistSize: -1
    }
    componentDidMount(){
        window.scrollTo(0,0);
        //fetch idToken
        // if (this.context.authUser) {
        //     this.context.authUser.getIdToken()
        // console.log("typeof user context = " + typeof this.context.user)
        if (typeof this.context.user === "object" && 
                    this.context.user !== null) {
            this.context.doGetIdToken()
            .then((idToken) => {
                // console.log('token : ' + idToken);
                //fetch user's playlist
                return axios.get('/playlist', {
                                    headers: {'Authorization': `Bearer ${idToken}`}
                                });
            })
            .then(res => {
                this.setState({
                    playlistItems: res.data,
                    playlistSize: res.data.length
                });
            })
            .catch(err => {
                console.log('Error while fetching playlist : ' + err);
                alert('Error while fetching playlist: ' + err);
                this.props.history.replace('/');
            });
        } else {
            this.props.history.replace('/');
        }
    }

    render() {
        const { classes } = this.props;
        let playlistMarkup;

        if (this.state.playlistSize > 0){
            playlistMarkup = this.state.playlistItems ? (
                this.state.playlistItems.map((playlistItem) => (
                    <PlaylistItem key={playlistItem.tId} playlistItem={playlistItem}/>
                ))
            ) : (<Grid item xs={12} align="center"> 
                    <CircularProgress variant="indeterminate" 
                        color="secondary" size={80} />
                </Grid>);
        } else if(this.state.playlistSize === -1){
            playlistMarkup = <Grid item xs={12} align="center"> 
                                <CircularProgress variant="indeterminate" 
                                    color="secondary" size={80} />
                            </Grid>
        } else {
            playlistMarkup = <Grid item xs={12}>
                            <Typography>&nbsp;</Typography>
                            <Typography variant="h6"
                                        align="center">
                                Empty playlist. 
                            </Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography variant="h6"
                                        align="center">
                                Choose artist from home page
                                to browse available concerts
                            </Typography>
                            </Grid>
        }

        return (
            <Grid container 
                    className={ classes.gridContainer } 
                    spacing={2} 
                    justify='flex-start'
                    alignItems='center' >
                {playlistMarkup}
            </Grid>
        );
    }
}
// playlist.contextType = AuthUserContext;
playlist.contextType = FirebaseContext;

export default withStyles(styles)(playlist)
