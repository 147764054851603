import React, { Component } from "react";
import ReactDOM from "react-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";

//Context API
import { FirebaseContext } from "../components/Firebase";

const styles = {
  gridContainer: {
    direction: "row",
    spacing: 4,
    justify: "center",
    alignItems: "center",
  },
  card: {
    position: "relative",
  },
  image: {
    height: 0,
    paddingTop: "56.25%",
  },
};

class paypalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    this.myRef = React.createRef();
    this.drawButton = this.drawButton.bind(this);

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.location.state.product) {
      alert("Missing data. Please try again ");
      this.props.history.replace("/");
    } else {
      //load paypal checkout js
      const paypalSDKjs = document.createElement("script");
      paypalSDKjs.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_ID}&disable-funding=credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo`;
      paypalSDKjs.async = true;
      paypalSDKjs.addEventListener("load", () => {
        this.setState({
          showButton: true,
        });
      });
      document.body.appendChild(paypalSDKjs);
    }
  }

  drawButton() {
    let { product } = this.props.location.state;
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return product.order_id;
        },
        onApprove: async (data, actions) => {
          document.getElementById("msg").style.display = "block";
          const order = await actions.order.capture();
          let paymentId = JSON.parse(JSON.stringify(order)).purchase_units[0]
            .payments.captures[0].id;
          const userData = {
            tId: product.tId,
            oDate: product.oDate,
            payGate: "paypal",
            payId: paymentId,
            orderId: order.id,
            signature: "",
            validity: product.validity,
          };
          const eventParams = {
            artistHandle: product.artistHandle,
            cTitle: product.cTitle,
            cCurr: product.tCurr,
            tPrice: product.tPrice,
          };
          //update payment details
          axios
            .post("/playlist/pay", userData, {
              headers: { Authorization: `Bearer ${product.idToken}` },
            })
            .then((res) => {
              //record analytics event
              this.context.eventAddToPlaylist(eventParams);
              //redirect to playlist
              this.props.history.replace("/playlist");
            })
            .catch((error) => {
              alert("Payment update error. ticket Id = " + product.tId);
              console.error("Payment update error. Order Id = " + product.tId);
              if (product.source === "event") {
                this.props.history.replace(`/events/${product.eventName}`);
              } else {
                this.props.history.replace(`/artists/${product.artistHandle}`);
              }
            });
        },
        onError: (err) => {
          alert("Payment error  = " + err.message);
          console.error("Payment error = " + err.message);
          if (product.source === "event") {
            this.props.history.replace(`/events/${product.eventName}`);
          } else {
            this.props.history.replace(`/artists/${product.artistHandle}`);
          }
        },
      })
      .render("#paypal-button-container");
  }

  render() {
    const { classes } = this.props;
    const { product } = this.props.location.state;
    let paypalMarkup;
    let cardImage;
    let contentMarkup;

    if (this.state.showButton) {
      this.drawButton();
    } else {
      paypalMarkup = (
        <CircularProgress variant="indeterminate" color="secondary" size={80} />
      );
    }

    if (product.cStatus === "Live" || product.cStatus === "Live-Preview") {
      cardImage = product.liveImg;
    } else {
      cardImage = product.cImg;
    }

    contentMarkup = ` ${product.cTitle} ${product.tCurr} ${product.tPrice}`;

    return (
      <Grid
        container
        className={classes.gridContainer}
        spacing={2}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} align="center">
          <Card className={classes.card}>
            <CardMedia className={classes.image} image={cardImage} />
            <CardContent>{contentMarkup}</CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} align="center">
          &nbsp;
          <div id="msg" style={{ display: "none" }}>
            <h2>Wait!! Payment processing still in progress...</h2>
          </div>
          <div id="paypal-button-container"></div>
          {paypalMarkup}
        </Grid>
      </Grid>
    );
  }
}
paypalButton.contextType = FirebaseContext;

export default withStyles(styles)(paypalButton);
