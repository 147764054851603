import React, { Component } from 'react';
import axios from 'axios';

//material ui components
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

//Context API
import { FirebaseContext } from '../components/Firebase';

//Components
import Concert from '../components/Concert';

const styles = {
    gridContainer: {
        spacing: 4,
        justify: 'center',
        alignItems: 'center'
    }
}

class preview extends Component {
    state = {
        concerts: null,
        concertCount: -1,
        couponCode: ""
    }

    componentDidMount(){
        window.scrollTo(0,0);
        let token;
        let coupon;

        if (this.context.auth.currentUser) {
            this.context.auth.currentUser.getIdToken()
            .then((idToken) => {
                token = idToken;
                return this.context.remoteConfig.fetchAndActivate();
            })
            .then(() => {
                coupon = localStorage.getItem('coupon');
                if (coupon === null || coupon === "") {
                    return Promise.reject('Coupon code not provided');
                } else {
                    //reset localStorage
                    localStorage.clear();
                    return axios.get('/preview', {
                        headers: {'Authorization': `Bearer ${token}`},
                        params: {
                            country_code: this.context.remoteConfig.getString('country'),
                            couponCode: coupon,
                        }                    
                    });
                }
            })
            .then(res => {
                // console.log(res.data);
                switch (res.status) {
                    case 220:
                        alert('Invalid link')
                        this.props.history.replace('/');
                        break;
                    case 221:
                        alert('Link expired')
                        this.props.history.replace('/');
                        break;
                    case 222:
                        alert('Concert does not exist')
                        this.props.history.replace('/');
                        break;
                    case 223:
                        alert('Concert is not available')
                        this.props.history.replace('/');
                        break;                        
                    default:
                        this.setState({
                            concerts: res.data,
                            concertCount: res.data.length,
                            couponCode: coupon
                        })
                        break;
                }
            })
            .catch(err => {
                alert("Unexpected Error : " + err);
                console.error(err)
                this.props.history.replace('/');

            });
                        
        } else {
            alert("Refreshing login token. Re-login if required");
            this.props.history.replace('/login');

        }
    }
    
    render() {
        const { classes } = this.props;
        let concertsMarkup = [];
        let circularMarkup;

        if (this.state.concertCount > 0) {
            concertsMarkup = this.state.concerts ? (
                this.state.concerts.map((concert) => (
                    <Concert key={concert.cId} 
                            concert={concert}
                            source="preview"
                            couponCode={this.state.couponCode} />
                ))
            ) : (<Grid item xs={12} align="center"> 
                    <CircularProgress variant="indeterminate" 
                        color="secondary" size={80} />
                </Grid>);
        } else if (this.state.concertCount === -1){
            circularMarkup = <Grid item xs={12} align="center"> 
                                <CircularProgress variant="indeterminate" 
                                    color="secondary" size={80} />
                            </Grid>
        } else {
            concertsMarkup = <Grid item xs={12}>
                                <Typography>&nbsp;</Typography>
                                <Typography variant="h6"
                                            align="center">
                                    Empty preview list. 
                                </Typography>
                            </Grid>
        }

        return (
            <Grid container
                    className={ classes.gridContainer } 
                    justify="flex-start" 
                    alignItems="center"
                    spacing={2} >
                <Grid item xs={12} sm={4} >
                    <Grid container
                            spacing={2} >
                        {concertsMarkup}
                    </Grid>
                </Grid>
                {circularMarkup}
            </Grid>
        );
    }
}
preview.contextType = FirebaseContext;

export default withStyles(styles)(preview)
