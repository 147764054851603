import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//material ui components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

//Context API
import { FirebaseContext } from "../components/Firebase";

//Components
import Artist from "../components/Artist";
import Event from "../components/Event";
import Announcements from "../components/Announcements";

//background image
const homeImgUrl =
  "https://firebasestorage.googleapis.com/v0/b/voncerts-df00d.appspot.com/o/website%2FHome-midres.jpg?alt=media";
const defaultImgUrl =
  "https://firebasestorage.googleapis.com/v0/b/voncerts-df00d.appspot.com/o/artists%2Fdefault.jpg?alt=media";

const styles = {
  gridColumn: {
    direction: "column",
    justify: "center",
    alignItems: "center",
    alignContent: "center",
  },
  gridPosterRow: {
    direction: "row",
    alignItems: "center",
    alignContent: "center",
  },
  gridRow: {
    direction: "row",
    alignItems: "flex-start",
    justify: "flex-start",
  },
  sectionTitle: {
    background: "#f5f5f5",
    align: "center",
    display: "block",
    paragraph: true,
  },
  card: {
    position: "relative",
  },
  postercard: {
    position: "relative",
  },
  posterMedia: {
    height: 450,
    maxWidth: 1600,
  },
  artistMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
  defaultMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
  artistOverlay: {
    position: "absolute",
    width: "100%",
    height: "30%",
    top: "70%",
    left: "0%",
    backgroundColor: "#ffffff",
    opacity: "0.60",
  },
};

class home extends Component {
  state = {
    artists: null,
    events: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    //fetch active artists
    axios
      .get("/artist/getArtists")
      .then((res) => {
        this.setState({
          artists: res.data,
        });
      })
      .catch((err) => {
        alert("Unexpected Error : " + err);
        console.error(err);
        this.props.history.replace("/");
      });

    //fetch active events
    axios
      .get("/event/getEvents")
      .then((res) => {
        this.setState({
          events: res.data,
        });
      })
      .catch((err) => {
        alert("Unexpected Error : " + err);
        console.error(err);
        this.props.history.replace("/");
      });
  }

  render() {
    const { classes } = this.props;
    let artistsMarkup = [];
    let eventsMarkup = [];

    //Artist section markup
    if (this.state.artists) {
      artistsMarkup.push(
        this.state.artists.map((artist) => (
          <Artist key={artist.artistHandle} artist={artist} />
        ))
      );
    } else {
      artistsMarkup.push(
        <Grid key={0} item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      );
    }

    //Event section markup
    if (this.state.events) {
      if (this.state.events.length > 0) {
        eventsMarkup.push(
          this.state.events.map((event) => (
            <Event key={event.eventName} event={event} />
          ))
        );
      } else {
        eventsMarkup.push(
          <Typography variant="h6">No Events Available</Typography>
        );
      }
    } else {
      eventsMarkup.push(
        <Grid key={1} item xs={12} align="center">
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={80}
          />
        </Grid>
      );
    }

    return (
      <Fragment>
        <Announcements target="home" />
        <Grid container className={classes.gridColumn} spacing={2}>
          <Grid item xs={12}>
            <Grid container className={classes.gridPosterRow} spacing={1}>
              <Grid item xs={12}>
                <Card className={classes.postercard}>
                  <CardMedia
                    className={classes.posterMedia}
                    image={homeImgUrl}
                  ></CardMedia>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Artists</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.gridRow} spacing={1}>
              {artistsMarkup}
              <Grid item xs={12} sm={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.defaultMedia}
                    image={defaultImgUrl}
                  ></CardMedia>
                  <CardContent className={classes.artistOverlay}>
                    <Typography variant="subtitle1">More to join</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Events</Typography>
          </Grid>
          <Grid item xs={12}>
            {eventsMarkup}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
home.contextType = FirebaseContext;

export default withStyles(styles)(home);
