import React, { Component } from 'react'

//material ui
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Message from './Message';
import MessageList from './MessageList';
import EmojiWindow from './EmojiWindow';
import Emojis from './Emojis';

const styles = {
    interactionContainer: {
        direction: "column",
        height: "100%",
    },
    messageContainer: {
        direction: "row",
        height: "80%",
        alignItems: "stretch"
    }
}

class Interaction extends Component {
    render() {
        const { classes, cId } = this.props;

        return (
            <Grid container 
                className={ classes.interactionContainer }
                spacing={1}
            >
                <Grid item xs={12} >
                    <Grid container 
                        direction="row" 
                        spacing={1} 
                    >
                        <Grid item>
                            <Message cId={cId} />                    
                        </Grid>
                        <Grid item>
                            <Emojis cId={cId} /> 
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{position:"relative"}}>
                    <MessageList cId={cId} />
                    <EmojiWindow cId={cId} />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Interaction);
