import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

//Mui imports
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import withStyles from '@material-ui/core/styles/withStyles';

//Context API
import { AuthUserContext } from '../components/Session';
import { CardContent, CardActionArea } from '@material-ui/core';

const styles = {
    gridItem: {
        xs: 12
    },
    card: {
        margin: 20,
    },
    image: {
        height: 0,
        paddingTop: '56.25%'  
    },
    imageOverlay: {
        position: 'absolute',
        width: '100%',
        height: '20%',
        top: '75%',
        left: '0%',
        backgroundColor: '#ffffff',
        opacity: '0.80'
    }
}

class PlaylistItem extends Component {
    render() {
        const { classes, playlistItem } = this.props;
        const { tId, cId, cStatus, eventName, artistHandle, 
            aDisplayName, cTitle, cComments, cMedia, cUrl, 
            cImg, liveImg, cFBAppId, cOGDesc, tVDate} = playlistItem;

        let cardImage;
        // let liveText;
        if (cStatus === "Live" || cStatus === "Live-Preview") {
            cardImage = liveImg;
            // liveText = "Premiere + ";
        } else {
            cardImage = cImg;
        }
    
        let displayDate;
        if (tVDate >= new Date(2099, 12, 29).toISOString()) {
            displayDate = "concert close"
        } else {
            // let utcToLocal = new Date(tVDate).getTime() - new Date().getTimezoneOffset()*60*1000;
            // let validTillDate = new Date(utcToLocal);
            let validTillDate = new Date(tVDate);
            displayDate = validTillDate.getFullYear() + "-" + 
                            (("0" + (validTillDate.getMonth() + 1)).slice(-2)) + "-" +
                            (("0" + validTillDate.getDate()).slice(-2)) + " " +
                            (("0" + validTillDate.getHours()).slice(-2)) + ":" +
                            (("0" + validTillDate.getMinutes()).slice(-2)) + ":" +
                            (("0" + validTillDate.getSeconds()).slice(-2));
        }
        return (
            <AuthUserContext.Consumer>
            { context => (
                <Fragment>
                { context.authUser &&  
                    <Grid item xs={12} sm={4}>
                        <Card className={classes.card}>
                            <CardActionArea>
                                <Link to={{
                                        pathname: `/artists/${artistHandle}/${cId}`,
                                        state: {playlistItem}
                                    }} style={{textDecoration: "none", color: "black" }}>
                                    <Typography variant="body2">
                                        &nbsp; View till {displayDate}
                                    </Typography>
                                    <CardMedia
                                        className={classes.image}
                                        image={cardImage}/>
                                    {/* <CardContent className={ classes.imageOverlay}> */}
                                        <IconButton aria-label="Play Concert">
                                            <PlayCircleOutlineIcon />
                                            <Typography variant="body2"> 
                                                &nbsp; {cTitle} 
                                            </Typography>
                                        </IconButton>
                                    {/* </CardContent> */}
                                </Link>
                            </CardActionArea>
                        </Card>
                    </Grid>
                }
                </Fragment>
            )}
            </AuthUserContext.Consumer>
        )
    }
}

export default withStyles(styles)(PlaylistItem);
