import React, { Component, Fragment } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

//Mui imports
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Radio from "@material-ui/core/Radio";

//Context API
import { AuthUserContext } from "./Session";
import { jssPreset, Typography, Button } from "@material-ui/core";

const minINRPrice = 100;
const minUSDPrice = 5;
const dividerColor = "#343434";
const styles = {
  card: {
    position: "relative",
  },
  image: {
    height: 0,
    paddingTop: "56.25%",
  },
  imageOverlay: {
    position: "absolute",
    width: "100%",
    height: "30%",
    top: "70%",
    left: "0%",
    backgroundColor: "#ffffff",
    opacity: "0.80",
  },
  priceOptions: {
    paddingLeft: "4%",
  },
};

class EventPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceIndex: null,
      priceOpen: null,
    };
    this.addConcert = this.addConcert.bind(this);
    this.selectPrice = this.selectPrice.bind(this);
    // this.changePriceOpen = this.changePriceOpen.bind(this);
  }

  addConcert(event) {
    let eventPass;
    if (
      this.props.event.cPriceFlag &&
      this.props.event.cPrice.length > 1 &&
      this.state.priceIndex === null
    ) {
      alert("Please select a price");
    } else {
      //If priceflag is false set priceInput to 0
      //if priceflag is true and single pricing model and open pricing assign priceOpen
      //if priceflag is true and tiered pricing and selected price is open, assign priceOpen
      //else assign selected price
      let priceInput;
      if (!this.props.event.cPriceFlag) {
        priceInput = 0;
      } else {
        let cPriceJSON;
        if (this.props.event.cPrice.length === 1) {
          cPriceJSON = JSON.parse(JSON.stringify(this.props.event.cPrice[0]));
        } else {
          cPriceJSON = JSON.parse(
            JSON.stringify(this.props.event.cPrice[this.state.priceIndex])
          );
        }

        if (cPriceJSON.price === 0) {
          priceInput = this.state.priceOpen;
        } else {
          priceInput = cPriceJSON.price;
        }
      }

      if (Number.isInteger(priceInput)) {
        if (
          !this.props.event.cPriceFlag ||
          (this.props.event.cPriceFlag &&
            ((this.props.event.cCurr === "INR" && priceInput >= minINRPrice) ||
              (this.props.event.cCurr === "USD" && priceInput >= minUSDPrice)))
        ) {
          if (
            this.props.event.cPriceFlag &&
            this.props.event.cPrice.length === 1
          ) {
            eventPass = {
              eventName: this.props.event.eventName,
              eStatus: this.props.event.eStatus,
              eStartDt: this.props.event.eStartDt,
              eDisplayName: this.props.event.eDisplayName,
              eImg: this.props.event.eImg,
              source: this.props.source,
              couponCode: this.props.couponCode,
              priceIndex: 0,
              priceOpen: priceInput,
            };
          } else {
            eventPass = {
              eventName: this.props.event.eventName,
              eStatus: this.props.event.eStatus,
              eStartDt: this.props.event.eStartDt,
              eDisplayName: this.props.event.eDisplayName,
              eImg: this.props.event.eImg,
              source: this.props.source,
              couponCode: this.props.couponCode,
              priceIndex: this.state.priceIndex,
              priceOpen: priceInput,
            };
          }

          console.log(`eventPass = ${JSON.stringify(eventPass)}`);

          this.props.history.push({
            pathname: "/addEventPass",
            state: { eventPass },
          });
        } else {
          if (this.props.event.cCurr === "INR") {
            alert(`Please enter a minimum price of INR ${minINRPrice} `);
          } else {
            alert(`Please enter a minimum price of USD ${minUSDPrice} `);
          }
        }
      } else {
        alert("Please enter a price");
      }
    }
  }

  selectPrice(event) {
    let select = document.querySelector("input[name='priceOption']:checked")
      .value;
    this.setState({
      priceIndex: select,
    });
  }

  // changePriceOpen(event){
  //     if (!isNaN(event.target.value)){
  //         let val = event.target.value * 1;
  //         this.setState({
  //             priceOpen: val
  //         });
  //     } else {
  //         event.preventDefault();
  //     }
  // }

  render() {
    const { classes, event } = this.props;
    const {
      orgId,
      eventName,
      eDisplayName,
      eStatus,
      eStartDt,
      eEndDt,
      eImg,
      passImg,
      cPriceFlag,
      cCurr,
      cPrice,
    } = event;

    let cardImage;
    cardImage = eImg;

    // let openPriceMarkup = <OutlinedInput
    //                         value={this.state.priceOpen}
    //                         onChange={this.changePriceOpen}
    //                         type="tel"
    //                         pattern="^\d+$"
    //                         style={{height:'30px', width:'70px', backgroundColor:"#ffffff"}}
    //                         />

    let priceBText;
    let cardPriceMarkup = [];
    let cardPrice = [];
    if (!cPriceFlag) {
      priceBText = (
        <Typography variant="body2">&nbsp; {eDisplayName} - Free </Typography>
      );
    } else {
      priceBText = (
        <Typography variant="body2">&nbsp; {eDisplayName}</Typography>
      );
      if (cPrice.length === 1) {
        let cPriceJSON = JSON.parse(JSON.stringify(cPrice[0]));
        cardPriceMarkup.push(<hr key={0.11} />);
        // if (cPriceJSON.price === 0) {
        //     cardPriceMarkup.push(<Typography key={0.12} variant="body2">
        //                             {liveText} View for {cPriceJSON.validity} day(s)
        //                             @ {cCurr} {openPriceMarkup} price of your choice
        //                         </Typography>);
        // } else {
        cardPriceMarkup.push(
          <Typography key={0.21} variant="body2">
            {eDisplayName} View for {cPriceJSON.validity} day(s) @ {cCurr}{" "}
            {cPriceJSON.price}
          </Typography>
        );
        // }
        cardPriceMarkup.push(<br key={0.13} />);
      } else {
        cardPriceMarkup.push(<hr key={0.11} />);
        let i;
        let cardPriceMarkupLine = [];
        for (i = 0; i < cPrice.length; i++) {
          let cPriceJSON = JSON.parse(JSON.stringify(cPrice[i]));
          cardPriceMarkupLine.push(
            <input
              type="radio"
              value={i}
              key={i}
              onChange={this.selectPrice}
              name="priceOption"
            />
          );
          cardPriceMarkupLine.push(<span key={i + 0.1}>&nbsp;</span>);
          // if (cPriceJSON.price === 0) {
          //     cardPriceMarkupLine.push(<span key={i+0.2}>&nbsp;
          //                                 {liveText} View for {cPriceJSON.validity} day(s)
          //                                 @ {cCurr} {openPriceMarkup} price of your choice
          //                             </span> );

          // } else {
          cardPriceMarkupLine.push(
            <span key={i + 0.2}>
              &nbsp;
              {eDisplayName} View for {cPriceJSON.validity} day(s) @ {cCurr}{" "}
              {cPriceJSON.price}
            </span>
          );
          // }
          cardPriceMarkupLine.push(<br key={i + 0.3} />);
        }
        cardPriceMarkup.push(
          <Typography key={i + 0.4} variant="body2">
            {cardPriceMarkupLine}
          </Typography>
        );
        cardPriceMarkup.push(<br key={i + 0.5} />);
      }
      if (cCurr === "INR") {
        cardPriceMarkup.push(<small key={0.22}>(India viewing only)</small>);
      }
      cardPrice.push(<CardContent key={0.13}>{cardPriceMarkup}</CardContent>);
    }
    return (
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Season pass
        </Typography>
        <Card className={classes.card}>
          <CardActionArea onClick={this.addConcert}>
            <CardMedia
              className={classes.image}
              image={cardImage}
              title={eDisplayName}
            />
          </CardActionArea>
        </Card>
        <Card>
          <IconButton onClick={this.addConcert} aria-label="Add to Playlist">
            <PlaylistAddIcon color="secondary" />
            <Typography variant="button" color="secondary">
              {" "}
              &nbsp; ADD &nbsp;{" "}
            </Typography>
            {priceBText}
          </IconButton>
          <div>{cardPrice}</div>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(EventPass));
