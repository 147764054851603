import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

//Styles
import withStyles from '@material-ui/core/styles/withStyles';

//Material UI imports
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

//Menu popup
import MenuListComposition from './Menu';
import { Typography } from '@material-ui/core';

//Context api
import { AuthUserContext } from './Session';

//images
// import logo from '../logo/voncerts-14.png';
const logo = 'https://firebasestorage.googleapis.com/v0/b/voncerts-df00d.appspot.com/o/logo%2Fvoncerts-14.png?alt=media';

const styles = {
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    },
    playlistCss: {
        marginLeft: '20px'
    },
    linkDefault: {
        textDecoration: 'none'
    }
}

class Navbar extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <AppBar>
                    <Toolbar  >
                        <Tooltip title="Home">
                            <Avatar variant="square"
                                src={logo}
                                component={Link}
                                to="/">
                            </Avatar>
                        </Tooltip>
                        <Tooltip  title="Home">
                            <Typography variant="subtitle2" 
                                    className={ classes.linkDefault }
                                    color="secondary"
                                    align="center"
                                    component={Link}
                                    to="/">
                                VONCERTS
                            </Typography>
                        </Tooltip>
                        <Typography className={classes.title} 
                            align='left'></Typography>
                        <AuthUserContext.Consumer>
                            { context => (
                                <Fragment>
                                    {context.authUser && 
                                        <Tooltip title="Playlist">
                                            <Button variant="contained" 
                                                    color="secondary" 
                                                    component={Link}
                                                    to="/playlist">
                                                My Playlist
                                            </Button>
                                        </Tooltip>
                                    }
                                </Fragment>
                            )}
                        </AuthUserContext.Consumer>
                        <MenuListComposition  />
                    </Toolbar>
                </AppBar>
            </div>                    
        )
    }
}

export default withStyles(styles)(Navbar) 
